import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { PrimaryButtonMedium, TertiaryButtonSmall } from "../Buttons/Buttons";
import { useStoreState } from "../../util/util";
import useSWR from "swr";
import type { ChatMessage } from "./MessageTimeline";
import { MessageTimeline } from "./MessageTimeline";
import {
  ChatIcon,
  CollapseIcon,
  ExpandIcon,
  LoadingIcon,
  NewXIcon,
} from "../Icons/Icons";
import { SendMessageToChatBot } from "./SendMessageToChatBot";
import { t } from "i18next";
import axios from "axios";
import { Notifications } from "../Notifications/NotificationsContext";
import { Auth } from "../Auth";
import ReactMarkdown from "react-markdown";
import type { StorefrontChatbotConfiguration } from "../../types/types";

type ChatBotCSSProps = {
  isOpen?: boolean;
  isExpanded?: boolean;
};

const ChatBotContainer = styled.div<ChatBotCSSProps>`
  position: fixed;
  z-index: 900;
  bottom: ${(props) => (props.isOpen ? "20px" : "0")};
  right: ${(props) => (props.isOpen ? "20px" : "0")};
  border: ${(props) =>
    props.isOpen ? `1px solid ${props.theme.primaryBorder}` : "0"};
  width: ${(props) =>
    props.isOpen ? (props.isExpanded ? "500px" : "380px") : "275px"};
  height: ${(props) =>
    props.isOpen ? (props.isExpanded ? "80vh" : "550px") : "90px"};
  background-color: ${(props) => (props.isOpen ? "#fff" : "transparent")};
  border-radius: ${(props) => (props.isOpen ? "8px" : "0")};
  box-shadow: ${(props) =>
    props.isOpen ? "0px 1px 20px 0px #6f777f66" : "none"};
  overflow: hidden;
  transition: width 0.3s, height 0.3s, background-color 0.3s, border-radius 0.3s;
  display: flex;
  flex-direction: column;
`;

const ChatBotIcon = styled(PrimaryButtonMedium)<ChatBotCSSProps>`
  position: absolute;
  top: ${(props) => (props.isOpen ? "5px" : "unset")};
  right: ${(props) => (props.isOpen ? "5px" : "unset")};
  display: ${(props) => (props.isOpen ? "none" : "flex")};
  border-radius: 25px;
  margin: 20px;
  box-shadow: ${(props) =>
    props.isOpen ? "none" : "0px 1px 20px 0px #6f777f66"};
  align-items: center;
  justify-content: space-around;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
`;

const ChatBotContent = styled.div<ChatBotCSSProps>`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  transition: all 0.3s ease-out;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
  position: relative;
`;

const TopSection = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;
const BottomSection = styled.div<ChatBotCSSProps>`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  flex-direction: column;
  width: 100%;
`;

const DisclaimerText = styled.div`
  font-size: 11px;
  text-align: center;
  margin-top: 8px;
  width: 100%;
  // ReactMarkdown introduces a p tag with padding by default
  p {
    margin-block-end: 0;
    margin-block-start: 0;
    padding: 0px;
  }
`;

const TypingAnimation = styled.div`

	position:relative;
	text-align:center;
	width:80px;
	height:100px;
	span {
		display:inline-block;
		width:6px;
		height:6px;
		border-radius:50%;
		margin-right:3px;
		background:#303131;
		animation: wave 0.9s linear infinite;

		&:nth-child(2) {
			animation-delay: -0.7s;
		}

		&:nth-child(3) {
			animation-delay: -0.5s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-12px);
	}
  }
`;

const ButtonSection = styled.div`
  display: flex;
  gap: 12px;
`;

export function ChatBot() {
  const [isOpen, setIsOpen] = useState(
    JSON.parse(sessionStorage.getItem("chatbotIsOpen") as string) ?? false
  );
  const [isExpanded, setIsExpanded] = useState(
    JSON.parse(sessionStorage.getItem("chatbotIsExpanded") as string) ?? false
  );
  const { storefront_id } = useStoreState();
  const [threadId, setThreadId] = useState(
    sessionStorage.getItem(`thread_${storefront_id}`)
  );
  const [threadMessages, setThreadMessages] = useState<ChatMessage[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { notifyError } = useContext(Notifications);
  const { roleIsSomeKindOfSeller } = useContext(Auth);

  const toggleChatBot = () => {
    sessionStorage.setItem("chatbotIsOpen", (!isOpen).toString());
    setIsOpen(!isOpen);
  };
  const toggleExpand = () => {
    sessionStorage.setItem("chatbotIsExpanded", (!isExpanded).toString());
    setIsExpanded(!isExpanded);
  };

  const { data: config, error: configError } =
    useSWR<StorefrontChatbotConfiguration>(
      roleIsSomeKindOfSeller ? `/v2/storefronts/${storefront_id}/chatbots` : ""
    );

  const defaultMessage: ChatMessage = {
    completed_at: null,
    content: [
      {
        text: {
          value: config?.intro_message || t("Hello, How may I help you"),
        },
        type: "text",
      },
    ],
    created_at: "1720994376",
    id: "0",
    role: "assistant",
    run_id: null,
    status: null,
    thread_id: "noThread",
  };
  const shouldFetchMessages = isOpen && threadId;

  const {
    data: messages,
    error: messagesError,
    mutate: mutateMessages,
  } = useSWR(
    shouldFetchMessages
      ? `/v2/storefronts/${storefront_id}/chatbots/threads/${threadId}/messages`
      : null,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (messagesError || configError) {
      notifyError("Something went wrong, please try again");
    }
  }, [messagesError, configError, notifyError]);

  const handleMessageInProgress = (inProgress: boolean, message?: string) => {
    setInProgress(inProgress);
    if (message) {
      setThreadMessages((prev) => [
        {
          id: "1",
          run_id: "1",
          role: "user",
          created_at: Date.now().toString(),
          thread_id: threadId || "noThread",
          completed_at: null,
          status: null,
          content: [
            {
              type: "text",
              text: {
                value: message,
              },
            },
          ],
        },
        ...prev,
      ]);
      if (contentRef?.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    }
  };

  const fetchLastMessageData = async (threadId: string, runId: string) => {
    try {
      const runIdResponse = await axios.get(
        `/v2/storefronts/${storefront_id}/chatbots/threads/${threadId}/messages?run_id=${runId}`
      );
      setInProgress(false);
      setThreadMessages((prev) => [runIdResponse?.data?.data[0], ...prev]);
      if (contentRef?.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    } catch (error) {
      notifyError("something went wrong");
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (messages) {
      setThreadMessages(messages.data);
    }
  }, [messages]);

  return (
    <>
      {config && config.is_customer_enabled && (
        <ChatBotContainer isOpen={isOpen} isExpanded={isExpanded}>
          <ChatBotIcon isOpen={isOpen} onClick={toggleChatBot}>
            <ChatIcon />
            <span style={{ marginLeft: "10px" }}>
              {t("Chat with an Assistant (Beta)")}
            </span>
          </ChatBotIcon>
          {isOpen && (
            <TopSection>
              <LogoContainer>
                {config?.avatar && (
                  <img
                    src={config?.avatar}
                    alt="logo"
                    style={{
                      maxWidth: "70px",
                      maxHeight: "50px",
                      margin: "0 10px 0 0",
                    }}
                  />
                )}
                {config?.name}
              </LogoContainer>
              <ButtonSection>
                <TertiaryButtonSmall onClick={toggleExpand}>
                  {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                </TertiaryButtonSmall>
                <TertiaryButtonSmall onClick={toggleChatBot}>
                  <NewXIcon />
                </TertiaryButtonSmall>
              </ButtonSection>
            </TopSection>
          )}
          <ChatBotContent
            isExpanded={isExpanded}
            isOpen={isOpen}
            ref={contentRef}
          >
            <MessageTimeline
              contentRef={contentRef}
              messages={
                (threadId && messages) ||
                (threadId && threadMessages.length < 1 && !messagesError)
                  ? threadMessages
                  : [...threadMessages, defaultMessage]
              }
            />
            {threadId && threadMessages.length < 1 && !messagesError && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingIcon width={30} />
              </div>
            )}
            {inProgress && (
              <TypingAnimation>
                <span></span>
                <span></span>
                <span></span>
              </TypingAnimation>
            )}
          </ChatBotContent>
          {((threadId && threadMessages.length > 0) || !threadId) && (
            <BottomSection isOpen={isOpen} isExpanded={isExpanded}>
              <SendMessageToChatBot
                mutateMessages={mutateMessages}
                fetchLastMessageData={fetchLastMessageData}
                handleMessageInProgress={handleMessageInProgress}
                handleSetThreadId={setThreadId}
                inProgress={inProgress}
                introMessage={
                  config?.intro_message || t("Hello, How may I help you")
                }
              />

              {config?.inside_login_disclaimer && (
                <DisclaimerText>
                  <ReactMarkdown>
                    {config?.inside_login_disclaimer}
                  </ReactMarkdown>
                </DisclaimerText>
              )}
            </BottomSection>
          )}
        </ChatBotContainer>
      )}
    </>
  );
}
