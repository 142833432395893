import type {
  OptionType,
  CurrencyCode,
  IPriceTierPaginatedOutput,
} from "../../../../../types/types";
import {
  useStoreState,
  makeUrlWithParams,
  useFormWrapper,
  markDefaultTerm,
} from "../../../../../util/util";
import { useContext, useState } from "react";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../../endpoints";
import useSWR from "swr";
import { Title } from "../../../../../components/Typography/Typography";
import { PrimaryButtonMedium } from "../../../../../components/Buttons/Buttons";
import { CreatePriceTierGroupStepTwo } from "./CreatePriceTierGroupStepTwo";
import styled from "styled-components/macro";
import { ConfirmDialog } from "../../../../../components/ConfirmDialog/ConfirmDialog";
import { SelectBoxWithConfirmDialog } from "../../../../../components/SelectBoxWithConfirmDialog/SelectBoxWithConfirmDialog";
import { screenSize } from "../../../../../theme";
import { Loader } from "../../../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import type { PIMProductBase } from "../../../../../types/types.PIM";

const StepOneInputsContainer = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`;

const InputRow = styled.div`
  display: flex;
  & > * {
    min-height: 60px; // Needed to prevent jumpiness when menu is shown.
    max-width: 372px; // Needed to prevent jumpiness with search box.
    flex-grow: 1;
    margin-right: 20px;
  }
  & > *:last-child {
    margin-right: 0px;
  }

  @media ${screenSize.medium} {
    flex-direction: column;
    & > * {
      margin-right: 0px;
      margin-bottom: 10px;
      max-width: unset;
    }
    & > *:last-child {
      margin-bottom: 0px;
    }
  }
`;

/**
 * The form for step one of creating a group of price tiers.
 *
 * This is a separate component from CreatePriceTierGroup in part because that
 * lets us defer calling useForm until we have the response with the default
 * terms to pre-fill into the form.
 */
export const CreatePriceTierGroupStepOne = ({
  sellerId,
  buyerTenantId,
  destinationOptions,
  deliveryTermsOptions,
  paymentTermsOptions,
  defaultDeliveryTermOption,
  defaultPaymentTermOption,
  currencyCode,
  onTierGroupCreated,
}: {
  sellerId: string;
  buyerTenantId: string;
  destinationOptions: OptionType<string>[];
  deliveryTermsOptions: OptionType<string>[];
  paymentTermsOptions: OptionType<string>[];
  defaultDeliveryTermOption: OptionType<string> | undefined;
  defaultPaymentTermOption: OptionType<string> | undefined;
  currencyCode: CurrencyCode | undefined;
  onTierGroupCreated: () => void;
}) => {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifyError } = useContext(Notifications);

  const [selectedProduct, setSelectedProduct] =
    useState<OptionType<PIMProductBase> | undefined>();
  const [pendingProductSelection, setPendingProductSelection] =
    useState<OptionType<PIMProductBase> | undefined>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [doneWithStepOne, setDoneWithStepOne] = useState(false);

  const methodsOfUseForm = useFormWrapper<{
    product: OptionType<PIMProductBase> | undefined;
    destination: OptionType<string> | undefined;
    delivery_terms: OptionType<string> | undefined;
    payment_terms: OptionType<string> | undefined;
  }>({
    defaultValues: {
      product: undefined,
      destination: undefined,
      delivery_terms: defaultDeliveryTermOption,
      payment_terms: defaultPaymentTermOption,
    },
  });
  const { errors, formState, watch } = methodsOfUseForm;

  const {
    destination: watchedDestination,
    delivery_terms: watchedDeliveryTerm,
    payment_terms: watchedPaymentTerm,
  } = watch(["destination", "delivery_terms", "payment_terms"]);

  const canProceedToStepTwo =
    selectedProduct &&
    watchedDestination &&
    watchedDeliveryTerm &&
    watchedPaymentTerm;

  const { data: priceTiersResponse, isValidating: isValidatingPriceTiers } =
    useSWR<IPriceTierPaginatedOutput>(
      selectedProduct?.value?.id &&
        watchedDestination &&
        watchedDeliveryTerm &&
        watchedPaymentTerm &&
        doneWithStepOne
        ? makeUrlWithParams(endpoints.v1_priceTiers(), {
            seller_id: sellerId,
            buyer_id: buyerTenantId,
            destination_id: watchedDestination.value,
            product_id: selectedProduct.value.id,
            delivery_term_id: watchedDeliveryTerm.value,
            payment_term_id: watchedPaymentTerm.value,
            currency: currencyCode,
          })
        : null,
      {
        onError: (error) => {
          notifyError(
            t("There was an error checking for existing price tiers"),
            {
              error,
            }
          );
        },
        revalidateOnFocus: false,
      }
    );
  // We're checking isValidatingPriceTiers here for the following reason:
  // We don't want to use the cached data, only fresh data, otherwise we pass
  // stale data to step two and the table gets initialized with stale data.
  // And then when SWR revalidates and sends the fresh data, the table is
  // still stuck on the stale data. (We could instead introduce a useEffect to
  // update the table data, but then the table flickers when the new data
  // arrives. Better to just wait for the fresh data in the first place.)
  const tiers = isValidatingPriceTiers ? undefined : priceTiersResponse?.data;

  const confirmMessage = t(
    `If you change this value any additions or edits to the price tiers below will be discarded. Do you want to continue?`
  );

  return (
    <>
      <Title>{t("Create Price Tiers")}</Title>
      <StepOneInputsContainer>
        <InputRow>
          <SearchSelectInfiniteScroll
            name={"product"}
            errors={errors}
            formState={formState}
            placeholder={"Search for Product"}
            baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              params.append("status", "published");
              if (buyerTenantId) {
                params.append("buyer_tenant_id", buyerTenantId);
              }
              return params;
            })()}
            getOptions={(response: PIMProductBase[]) =>
              response.reduce<OptionType<PIMProductBase>[]>(
                (acc, cur) =>
                  !selectedProduct
                    ? [...acc, { label: cur.name, value: cur }]
                    : cur.id === selectedProduct.value.id
                    ? acc
                    : [...acc, { label: cur.name, value: cur }],
                []
              )
            }
            value={selectedProduct || null}
            defaultOptions
            onChange={(option: OptionType<PIMProductBase>) => {
              // onChange is called when the user selects a product.
              if (doneWithStepOne) {
                setPendingProductSelection(option);
                setShowConfirmDialog(true);
              } else {
                setSelectedProduct(option);
              }
            }}
          />
          {/* This confirm dialog is used only for the product input. */}
          <ConfirmDialog
            show={showConfirmDialog}
            closeDialog={() => setShowConfirmDialog(false)}
            confirmMessage={confirmMessage}
            handleConfirm={() => {
              setSelectedProduct(pendingProductSelection);
              setDoneWithStepOne(false);
              setShowConfirmDialog(false);
            }}
          />
          <SelectBoxWithConfirmDialog
            methodsOfUseForm={methodsOfUseForm}
            name={"destination"}
            placeholder={"To Destination"}
            options={destinationOptions}
            showDialogOnChange={doneWithStepOne}
            confirmMessage={confirmMessage}
            handleConfirm={() => setDoneWithStepOne(false)}
          />
        </InputRow>
        <InputRow>
          <SelectBoxWithConfirmDialog
            methodsOfUseForm={methodsOfUseForm}
            name={"delivery_terms"}
            placeholder={"Shipping Terms"}
            options={markDefaultTerm(
              deliveryTermsOptions,
              defaultDeliveryTermOption
            )}
            defaultOption={defaultDeliveryTermOption}
            showDialogOnChange={doneWithStepOne}
            confirmMessage={confirmMessage}
            handleConfirm={() => setDoneWithStepOne(false)}
            setDefaultTerm={!!defaultDeliveryTermOption}
          />
          <SelectBoxWithConfirmDialog
            methodsOfUseForm={methodsOfUseForm}
            name={"payment_terms"}
            placeholder={"Payment Terms"}
            options={markDefaultTerm(
              paymentTermsOptions,
              defaultPaymentTermOption
            )}
            defaultOption={defaultPaymentTermOption}
            showDialogOnChange={doneWithStepOne}
            confirmMessage={confirmMessage}
            handleConfirm={() => setDoneWithStepOne(false)}
            setDefaultTerm={!!defaultPaymentTermOption}
          />
        </InputRow>
        {!doneWithStepOne && (
          <PrimaryButtonMedium
            onClick={() => setDoneWithStepOne(true)}
            disabled={!canProceedToStepTwo}
          >
            {t("Set up tiers")}
          </PrimaryButtonMedium>
        )}
      </StepOneInputsContainer>

      {doneWithStepOne && !tiers && (
        // Loader is `position: absolute;` so wrap it in `relative` container
        // so the loader appears in the correct position.
        <div style={{ position: "relative" }}>
          <Loader isLoading={true} />
        </div>
      )}

      {doneWithStepOne &&
        tiers &&
        selectedProduct &&
        watchedDestination &&
        watchedPaymentTerm &&
        watchedDeliveryTerm && (
          <CreatePriceTierGroupStepTwo
            tiers={tiers}
            destinationId={watchedDestination.value}
            paymentTermId={watchedPaymentTerm.value}
            deliveryTermId={watchedDeliveryTerm.value}
            productSkus={[
              ...selectedProduct.value.product_skus.filter(
                (sku) => !sku.is_sample
              ),
            ]}
            sellerTenantId={sellerId}
            buyerTenantId={buyerTenantId}
            currencyCode={currencyCode || "USD"}
            onTierCreated={onTierGroupCreated}
          />
        )}
    </>
  );
};
