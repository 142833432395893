import type { AxiosError } from "axios";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components/macro";
import useSWR from "swr";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import {
  ButtonWithConfirmDialog,
  CancelButton,
  CancelTransactionDialogButton,
  EditButton,
  GoBackButton,
  PrimaryButtonFitContainer,
  PrimaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import {
  ConfirmDialog,
  GenericDialogBody,
} from "../../../components/ConfirmDialog/ConfirmDialog";
import { ContactInfoBlockSmall } from "../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { DisplayOrEditLotNumbers } from "../../../components/DisplayAndEditLotNumbers/DisplayLotNumbers";
import { DropDown } from "../../../components/DropDown/DropDown";
import { ErrorPlaceholder } from "../../../components/Error";
import { IDDesktop } from "../../../components/IDs/IDs";
import type { KeyValuePair } from "../../../components/KeyValueDisplay";
import { KeyValueDisplay } from "../../../components/KeyValueDisplay";
import {
  HeaderLeft,
  HeaderRight,
  Row,
} from "../../../components/Layout/Layout";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { SellerOrderStatus } from "../../../components/OrderStatus/OrderStatus";
import {
  TransactionStatusPath,
  getOrderStatusItemsForDropDown,
} from "../../../components/OrderStatusPath/OrderStatusPath";
import { PoNumberAndDocumentLink } from "../../../components/PoNumberAndDocumentLink/PoNumberAndDocumentLink";
import {
  PriceDetails,
  calculatePriceDetails,
} from "../../../components/PriceDetails/PriceDetails";
import { SellerCreateOrEditShipmentAdvice } from "../../../components/SellerCreateOrEditShipmentAdvice/SellerCreateOrEditShipmentAdvice";
import { ShipmentAdviceSection } from "../../../components/ShipmentAdviceSection/ShipmentAdviceSection";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { TaxExemptDocument } from "../../../components/TaxExempt/TaxExemptDocument";
import { Timeline } from "../../../components/Timeline/Timeline";
import { OrderItem } from "../../../components/TransactionItem/OrderItem";
import {
  H3,
  SoftHeader,
  SoftHeaderMediumDarkText,
  SoftHeaderPrimaryTextColor,
} from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import { Flex, Form } from "../../../layout/FormLayout";
import {
  Card,
  PageHeader,
  PageTitle,
  PageWrapper,
  TwoColumnOrderTotalSection,
} from "../../../layout/portalPageLayout";
import {
  DetailPageContentWrapper,
  KeyValueContainer,
  QuoteOrderContent,
  QuoteTermsContainer,
  TimelineWrapper,
  WideDetails,
} from "../../../layout/shared/DetailPageLayout/DetailPageLayout";
import type {
  AccountManagerContactInfo,
  IPurchaseOrder,
  IQuoteRequest,
  IShipmentAdvice,
  LotNumber,
  OrderMessageArgs,
  OrderStatus,
  OrderStatusLabel,
  PaginatedTransactionEvents,
} from "../../../types/types";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import {
  convertOrderLabelToOrderStatus,
  convertOrderStatusToOrderLabel,
  convertOrderStatusToOrderStatusAction,
  formatDateTime,
  isAxiosError,
  useFormWrapper,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { formatPrice } from "../../../util/util-components";
import { PriceDetailsColumn } from "../../Buyer/BuyerQuoteDetail/BuyerQuoteDetail";
import { HorizontalSeparator } from "../SellerQuoteDetailPage/SellerQuoteDetailPageContent";
import { InvoiceSection } from "./InvoiceSection/InvoiceSection";
import { PackingListSection } from "./PackingList/PackingList";
import { LoadingIcon } from "../../../components/Icons/Icons";
import { AddDocumentToTransaction } from "../AddDocumentToTransaction/AddDocumentToTransaction";
import { TransactionsDocumentView } from "../../../components/DocumentView/TransactionsDocumentView";
import { get_existing_documents } from "../AddDocumentToTransaction/utils";

const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  & > div[class*="order_status"] {
    position: relative;
    top: -6px;
    min-width: 140px;
  }
`;

const WarningText = styled.span`
  color: ${({ theme }) => theme.warningTextColor};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-top: 4px;
`;

interface DeclineOrderButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  t: (s: string) => string;
}

const DeclineOrderButton = (props: DeclineOrderButtonProps) => (
  <CancelButton type="button" {...props}>
    {props.t("Decline this order")}
  </CancelButton>
);

export const CancelTransactionButton = (props: DeclineOrderButtonProps) => (
  <CancelButton type="button" {...props}>
    {props.t("Cancel")}
  </CancelButton>
);

/**
 * Shared page component that displays details of a single order, as viewed by
 * a seller (as opposed to a buyer).
 *
 * Used by seller and seller admin.
 */
export const SellerOrderDetailPage = providePrivatePageProps(({ user }) => {
  const { t } = useTranslation();
  const { order_id } =
    useParams<{
      order_id: string;
    }>();
  const { accountPath } = useRoutePath();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });

  const { notifyError, notifySuccess } = useNotifications();
  const [mode, setMode] = useState<"order" | "seller_update_price">("order");
  const [showEditLotNumber, setShowEditLotNumber] = useState(false);
  const [showShipmentAdviceForm, setShowShipmentAdviceForm] = useState(false);
  const [hasInvoice, setHasInvoice] = useState(false);
  const [start_time] = useState(Date.now());
  const [adviceToEdit, setAdviceToEdit] =
    useState<IShipmentAdvice | undefined>(undefined);
  const [showStatusChangeDialogBox, setShowStatusChangeDialogBox] =
    useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<OrderStatus>();
  const [isAcceptingOrder, setIsAcceptingOrder] = useState(false);
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const [is_invoice_requested, set_is_invoice_requested] = useState(false);
  const [has_shown_invoice_error, set_has_shown_invoice_error] =
    useState(false);
  const {
    storefront_id,
    storefront_metadata: { enable_invoice_auto_generation },
  } = useStoreState();
  const { mutateNotifications } = useInAppNotifications(storefront_id, user);
  const { handleSubmit } = useFormWrapper({});
  const theme = useTheme();

  const {
    data: order,
    error: orderError,
    mutate: mutateOrder,
  } = useSWR<IPurchaseOrder, AxiosError>(
    `/v1/storefronts/${storefront_id}/orders/${order_id}`
  );

  const { data: quote } = useSWR<IQuoteRequest, AxiosError>(
    order?.quote_request_id
      ? `/v1/storefronts/${storefront_id}/quotes/${order.quote_request_id}`
      : null
  );

  const {
    data: orderEvents,
    error: orderEventsError,
    mutate: mutateEvents,
  } = useSWR<PaginatedTransactionEvents, AxiosError>(
    order_id
      ? `/v1/storefronts/${storefront_id}/orders/${order_id}/events?limit=100`
      : null,
    {
      onSuccess: (orders) => orders.data.reverse(),
    }
  );

  const mutateOrderAndEvents = async () => {
    await mutateOrder();
    await mutateEvents();
  };

  const handleSendMessage = async (message: string) => {
    try {
      await Axios.post<OrderMessageArgs>(
        `/v1/storefronts/${storefront_id}/orders/${order_id}/messages`,
        {
          message: message,
          message_type: "Other",
        }
      );
      await mutateEvents();
    } catch (error) {
      notifyError(t("Error sending message"), { error });
    }
  };

  useEffect(() => {
    setSelectedOrderStatus(order?.status ?? undefined);
    setHasInvoice(
      order?.documents.find((doc) => doc.kind === "invoice") !== undefined
    );
  }, [order?.documents, order?.status]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;
    const timeout = 30000;
    if (
      selectedOrderStatus === "invoiced" &&
      enable_invoice_auto_generation &&
      !hasInvoice
    ) {
      setShowInvoiceDialog(true);
      intervalId = setInterval(() => {
        if (Date.now() - start_time > timeout) {
          clearInterval(intervalId);
          setShowInvoiceDialog(false);
          set_is_invoice_requested(true);
        } else {
          mutateOrder();
        }
      }, 2000);
    }
    if (hasInvoice) {
      setShowInvoiceDialog(false);
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [
    enable_invoice_auto_generation,
    hasInvoice,
    mutateOrder,
    notifyError,
    selectedOrderStatus,
    start_time,
    t,
  ]);

  let accountManagerContactInfo: AccountManagerContactInfo | undefined =
    undefined;
  if (order && order.sold_by) {
    accountManagerContactInfo = order.sold_by.tenant_user_contact_info;
  }

  const isLoadingOrder = !order && !orderError;

  if (!hasInvoice && is_invoice_requested && !has_shown_invoice_error) {
    notifyError(
      t("Failed to generate an invoice. Please refresh the page to try again.")
    );
    set_has_shown_invoice_error(true);
  }

  if (isLoadingOrder) {
    return <DelayedSpinner />;
  }

  if (orderError) {
    return (
      <ErrorPlaceholder message={t("There was an error loading the order")} />
    );
  }

  if (order) {
    mutateNotifications();
    const shipmentEta = moment(order.required_eta).format("MMMM DD, YYYY");
    const currencyCode = order.currency;
    const terms: KeyValuePair[] = [
      { key: t("Shipping Terms"), value: order.delivery_term?.name || "--" },
      { key: t("Payment Terms"), value: order.payment_term?.name || "--" },
      { key: t("Shipment ETA"), value: shipmentEta || "--" },
      { key: t("Payment Method"), value: order.payment_mode?.name || "--" },
    ];
    const generateAcceptText = () => {
      if (mode === "order") return t("Accept");
      if (mode === "seller_update_price") {
        const plural = order.items.length > 1;
        return plural
          ? t("Update Prices & Accept Order")
          : t("Update Price & Accept Order");
      }
    };

    const updateOrderStatusAndCloseDialogBox = async () => {
      setShowStatusChangeDialogBox(false);
      try {
        await Axios.patch(
          endpoints.v1_storefronts_id_orders_id_order_status(
            storefront_id,
            order.id,
            convertOrderStatusToOrderStatusAction(selectedOrderStatus!)
          )
        );
        await mutateOrderAndEvents();
      } catch (error) {
        const message = (error as AxiosError)?.response?.data?.message
          ? (error as AxiosError)?.response?.data?.message
          : t("There was an error updating the order status");
        notifyError(message as string, { error });
        setSelectedOrderStatus(order.status);
      }
    };

    const closeOrderStatusDialog = () => {
      setShowStatusChangeDialogBox(false);
      setSelectedOrderStatus(order.status);
    };

    const handleSellerStatusChange = (label: OrderStatusLabel) => {
      const status = convertOrderLabelToOrderStatus(label);
      if (
        status !== "payment_received" &&
        status !== order.status &&
        status !== selectedOrderStatus
      ) {
        setShowStatusChangeDialogBox(true);
        setSelectedOrderStatus(status);
      }
    };

    const handleOrderAcceptance = async (values: Record<string, string>) => {
      try {
        setIsAcceptingOrder(true);
        await Axios.patch(
          `/v1/storefronts/${storefront_id}/orders/${order_id}/accept `,
          {
            item_prices: Object.entries(values).map((item) => ({
              id: item[0],
              price: item[1],
            })),
          }
        );
        await mutateOrderAndEvents();
        setMode("order");
      } catch (error) {
        if (
          isAxiosError(error) &&
          error?.response?.data?.status_code === "403"
        ) {
          notifyError(error.response.data.message);
        } else {
          notifyError(
            t(`There was an error accepting order {{orderNumber}}`, {
              orderNumber: order.number,
            }),
            {
              error,
            }
          );
        }
      } finally {
        setIsAcceptingOrder(false);
      }
    };

    const handleOrderDecline = async (id: string) => {
      try {
        await Axios.patch(
          `/v1/storefronts/${storefront_id}/orders/${id}/decline`
        );
        notifySuccess(`Order ${order.number} has been declined`);
        await mutateOrderAndEvents();
      } catch (error) {
        notifyError(
          t(`There was an error declining order {{orderNumber}}`, {
            orderNumber: order.number,
          }),
          {
            error,
          }
        );
      }
    };

    type formOutput = {
      reasonForCancellation: string;
    };

    const handleCancelTransaction = async (values: formOutput) => {
      // console.log("values123", values);
      try {
        await Axios.patch(
          `/v1/storefronts/${storefront_id}/orders/${order_id}/cancel`
        );
        notifySuccess(`Order ${order.number} has been Canceled`);
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.data?.status_code === "403") {
            notifyError(error.response.data.message);
          } else {
            notifyError(error.response?.data?.message);
          }
        } else {
          notifyError(
            t(`There was an error canceling order {{orderNumber}}`, {
              orderNumber: order.number,
            }),
            {
              error,
            }
          );
        }
      }
    };

    const cancelTransStatus = [
      "accepted",
      "in_progress",
      "invoiced",
      "shipped",
    ];

    const isOrderLotNumbers = () => {
      let orderLots: LotNumber[] = [];
      order.items.forEach((item) => {
        orderLots = [...orderLots, ...item.lot_numbers];
      });
      return orderLots.length > 0;
    };
    const handleLotNumberSave = () => {
      setShowEditLotNumber(false);
      mutateOrder();
    };
    const canShowStatusPath = () =>
      order.status !== "declined" && order.status !== "cancelled";
    const canShowStatusDropdown = () =>
      order.status !== "new" &&
      order.status !== "completed" &&
      !order.status.includes("pending");
    const poDocument = order.documents.find(
      (doc) => doc.kind === "purchase_order"
    );

    // At time of writing the "delivered" and "closed" order statuses are still
    // not being used, so for now we can default to read-only invoice for
    // "closed" and read/write invoice for "delivered".
    const isNewOrder =
      order.status === "new" && !order.status.includes("pending");
    const showInvoiceSection = !isNewOrder;
    const canEdit =
      order.status !== "completed" &&
      order.status !== "declined" &&
      order.status !== "cancelled" &&
      order.status !== "payment_received" &&
      order.status !== "shipped" &&
      !isNewOrder;

    const somePriceTiersExpired =
      order &&
      (order.status === "new" || order.status.includes("pending")) &&
      order.items.some((item) => !item.price_per_unit);

    const priceTiersExpiredMessage = t(
      `This order can no longer be accepted because the price offers have expired for at least one of the items in the order.`
    );

    const confirmOrderStatusChangeMessage =
      selectedOrderStatus !== "completed"
        ? t("Are you sure you want to change the status to {{orderStatus}}?", {
            orderStatus: convertOrderStatusToOrderLabel(
              // there will always be selectedOrderStatus at this point
              // But this is to satisfy typescript
              selectedOrderStatus ?? "accepted"
            ),
          })
        : ((
            <>
              {t("Are you sure you want to change the status to Completed?")}
              <WarningText>
                {t(
                  "There will be no further changes allowed in any of the sections"
                )}
              </WarningText>
            </>
          ) as unknown as string);

    const maybeInvoiceCharges = (() => {
      if (order.invoice && order.invoice.additional_charges) {
        return order.invoice.additional_charges;
      } else return [];
    })();

    const feesAndCharges = [...order.fees, ...maybeInvoiceCharges];

    const { total, subtotalString, totalString } = calculatePriceDetails({
      items: order.items,
      fees: feesAndCharges,
      currencyCode,
    });

    // TODO: centralize this permission logic, when we have time to do it right!
    const orderThreshold =
      order.currency === "USD"
        ? user.order_threshold_usd
        : user.order_threshold_local;

    const totalIsAboveThreshold =
      total && orderThreshold && total > orderThreshold;

    const orderThresholdString = orderThreshold
      ? formatPrice(orderThreshold, order.currency)
      : "--";
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(query?.offset ?? 0));
    params.append("perPage", String(query?.perPage ?? 10));
    ((query?.status ?? []).filter((status) => !!status) as string[]).forEach(
      (status) => params.append("status", status)
    );

    const isLoadingEvents = !orderEvents && !orderEventsError;

    return (
      <PageWrapper>
        <Link to={`${accountPath}/orders?${params}`}>
          <GoBackButton text={t("Orders")} />
        </Link>
        <PageHeader>
          <HeaderLeft>
            <PageTitle>{t("Order")}</PageTitle>
            <IDDesktop>{`${order.number}`}</IDDesktop>
          </HeaderLeft>
          <HeaderRight>
            <SellerOrderStatus t={t} status={order.status} position={"right"} />
          </HeaderRight>
        </PageHeader>
        <Flex>
          <SoftHeader style={{ margin: "0 3px 5px 0" }}>
            {t("Created By")}:
          </SoftHeader>
          <SoftHeaderPrimaryTextColor>
            {`${order.created_by || `--`} (${order.created_by_company_name})`}
          </SoftHeaderPrimaryTextColor>
        </Flex>
        <Flex>
          <SoftHeader style={{ margin: "0 3px 0 0" }}>
            {t("Created On")}:
          </SoftHeader>
          <SoftHeaderPrimaryTextColor>
            {formatDateTime(order.created_at)}
          </SoftHeaderPrimaryTextColor>
          {(formatDateTime(order.modified_at) !==
            formatDateTime(order.created_at) ||
            order.created_by !== order.modified_by) && (
            <>
              <SoftHeader style={{ margin: "0 2px 0 10px" }}>
                {t("Last Modified")}:
              </SoftHeader>
              <SoftHeaderPrimaryTextColor>
                {order.modified_at
                  ? `${formatDateTime(order.modified_at)} By ${
                      order.modified_by
                    }`
                  : `--`}
              </SoftHeaderPrimaryTextColor>
            </>
          )}
        </Flex>
        <HorizontalSeparator />

        <DetailPageContentWrapper>
          <QuoteOrderContent>
            {canShowStatusPath() && (
              <StatusContainer style={{ position: "relative" }}>
                <TransactionStatusPath
                  transactionStatus={order.status}
                  view="sellerOrder"
                />
                {canShowStatusDropdown() && (
                  <DropDown
                    items={getOrderStatusItemsForDropDown(order.status)}
                    className="order_status"
                    activeItem={convertOrderStatusToOrderLabel(order.status)}
                    direction={"left"}
                    clickHandler={handleSellerStatusChange}
                    showIcon
                    placeholder={t("Change status")}
                  />
                )}
                <ConfirmDialog
                  show={showStatusChangeDialogBox}
                  confirmMessage={confirmOrderStatusChangeMessage}
                  closeDialog={closeOrderStatusDialog}
                  handleConfirm={updateOrderStatusAndCloseDialogBox}
                />
              </StatusContainer>
            )}
            <Card>
              <PageHeader style={{ width: "fit-content" }}>
                <HeaderLeft>
                  <H3>{t("Summary")}</H3>
                </HeaderLeft>
              </PageHeader>
              <HorizontalSeparator />
              <WideDetails>
                <ContactInfoBlockSmall
                  address={order.shipping_address}
                  header={t("Ship to")}
                  testid={"shipping-address"}
                />
                <ContactInfoBlockSmall
                  address={order.billing_address}
                  header={t("Bill to")}
                  testid={"billing-address"}
                />
                <ContactInfoBlockSmall
                  address={order.seller_address}
                  accountManagerContactInfo={accountManagerContactInfo}
                  header={t("Sold By")}
                  testid={"shipping-address"}
                />
              </WideDetails>
              <H3>{t("Items")}</H3>

              <Form noValidate onSubmit={handleSubmit(handleOrderAcceptance)}>
                {order.items.map((item, index) => {
                  return (
                    <OrderItem item={item} index={index} key={index} t={t} />
                  );
                })}
                <TwoColumnOrderTotalSection>
                  <QuoteTermsContainer>
                    <KeyValueContainer>
                      <KeyValueDisplay data={terms} />
                      {order?.is_tax_exempt && order?.tax_exempt_document && (
                        <TaxExemptDocument
                          document={order.tax_exempt_document}
                        />
                      )}
                    </KeyValueContainer>
                    {/*TODO: once we go to statuses beyond acceptance this
                   will have to be reworked*/}
                    {(order.status === "new" ||
                      order.status.includes("pending")) && (
                      <ButtonWithConfirmDialog
                        Button={(props) => (
                          <DeclineOrderButton t={t} {...props} />
                        )}
                        type="button"
                        testid={"order-decline-button"}
                        handleConfirm={() => handleOrderDecline(order_id)}
                        confirmMessage={t(
                          "Are you sure you want to decline this order?"
                        )}
                      />
                    )}
                    {cancelTransStatus.includes(order?.status) && (
                      <CancelTransactionDialogButton
                        Button={(props) => (
                          <CancelTransactionButton t={t} {...props} />
                        )}
                        type="button"
                        testid={"transaction-cancel-button"}
                        handleConfirm={(values) =>
                          handleCancelTransaction(values)
                        }
                        confirmMessage={t(
                          "Cancelling this transaction will require approval from [buyer / seller point of contact name], and cannot be undone. Please confirm if you would like to proceed and provide a reason for cancellation."
                        )}
                        heading={t("Cancel Transaction")}
                      />
                    )}
                  </QuoteTermsContainer>
                  <PriceDetailsColumn>
                    <PriceDetails
                      subtotal={subtotalString}
                      total={totalString}
                      fees={feesAndCharges}
                      currencyCode={currencyCode}
                      payment={order.payments}
                    />
                    {(() => {
                      switch (order.status) {
                        case "accepted":
                          return null;
                        case "new":
                          return somePriceTiersExpired ? (
                            <ButtonWithConfirmDialog
                              Button={PrimaryButtonFitContainer}
                              testid={"accept-order-button"}
                              buttonText={generateAcceptText()}
                              confirmMessage={priceTiersExpiredMessage}
                            />
                          ) : totalIsAboveThreshold ? (
                            <ButtonWithConfirmDialog
                              Button={PrimaryButtonFitContainer}
                              testid={"accept-order-button"}
                              buttonText={generateAcceptText()}
                              confirmMessage={t(
                                `The total price ({{totalString}}) is larger than your order threshold ({{orderThresholdString}}). The order cannot be accepted.`,
                                { totalString, orderThresholdString }
                              )}
                            />
                          ) : (
                            <PrimaryButtonFitContainer
                              loading={isAcceptingOrder}
                            >
                              {generateAcceptText()}
                            </PrimaryButtonFitContainer>
                          );
                      }
                    })()}
                  </PriceDetailsColumn>
                </TwoColumnOrderTotalSection>
              </Form>
            </Card>
            <GenericDialogBody
              show={showInvoiceDialog}
              closeDialog={() => setShowInvoiceDialog(false)}
            >
              <div
                style={{
                  padding: "16px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <LoadingIcon
                  width={15}
                  height={15}
                  fill={theme.primaryButtonTextColor}
                />
                <SoftHeaderMediumDarkText>
                  {t("Please wait, your invoice is being generated...")}
                </SoftHeaderMediumDarkText>
              </div>
            </GenericDialogBody>
            {showInvoiceSection && (
              <Card>
                {!canEdit ? (
                  <InvoiceSection order={order} readOnly={true} />
                ) : (
                  <InvoiceSection
                    order={order}
                    readOnly={false}
                    mutateOrderAndEvents={mutateOrderAndEvents}
                  />
                )}
              </Card>
            )}
            {order.alternative_po_number && (
              <Card>
                <H3>{t("PO Number")}</H3>
                <PoNumberAndDocumentLink
                  poNumber={order.alternative_po_number}
                  poDocument={poDocument}
                />
              </Card>
            )}

            {!isNewOrder && (
              <Card>
                <Row>
                  <H3>{t("Lot Number")}</H3>
                  {showEditLotNumber && (
                    <CancelButton
                      onClick={() => {
                        setShowEditLotNumber(false);
                        mutateOrder();
                      }}
                    >
                      {t("Cancel")}
                    </CancelButton>
                  )}
                  {!showEditLotNumber &&
                    canEdit &&
                    (!isOrderLotNumbers() ? (
                      <PrimaryButtonMedium
                        onClick={() => setShowEditLotNumber(true)}
                      >
                        {t("Add")}
                      </PrimaryButtonMedium>
                    ) : (
                      <EditButton
                        testid="editLotNumber"
                        onClick={() => setShowEditLotNumber(true)}
                      />
                    ))}
                </Row>
                <DisplayOrEditLotNumbers
                  orderItems={order.items}
                  orderId={order.id}
                  editMode={showEditLotNumber}
                  handleSave={handleLotNumberSave}
                />
              </Card>
            )}

            {!isNewOrder && (
              <Card>
                <Row>
                  <H3>{t("Shipment Advice")}</H3>
                </Row>
                {order.shipment_advices &&
                order.shipment_advices?.length > 0 ? (
                  order.shipment_advices.map((advice) => (
                    <ShipmentAdviceSection
                      key={advice.id}
                      shipment_advice={advice}
                      orderId={order.id}
                      canEdit={canEdit}
                      mutateData={mutateOrderAndEvents}
                      handleEditShipmentAdvice={() => {
                        setAdviceToEdit(advice);
                        setShowShipmentAdviceForm(true);
                      }}
                    />
                  ))
                ) : (
                  // If nothing has been created yet the top level object will not exist
                  <Row>
                    <span>N/A</span>
                  </Row>
                )}
                {canEdit && (
                  <Row style={{ marginTop: "20px" }}>
                    <PrimaryButtonMedium
                      onClick={() => {
                        setAdviceToEdit(undefined);
                        setShowShipmentAdviceForm(true);
                      }}
                    >
                      {t("Add Shipment Advice")}
                    </PrimaryButtonMedium>
                  </Row>
                )}
                <SlideOut
                  show={showShipmentAdviceForm}
                  closeFlyout={() => setShowShipmentAdviceForm(false)}
                >
                  <SellerCreateOrEditShipmentAdvice
                    shipmentAdvice={adviceToEdit}
                    shippingDocument={adviceToEdit?.shipment_advice_document}
                    mode={adviceToEdit ? "edit" : "create"}
                    closeForm={() => {
                      setShowShipmentAdviceForm(false);
                      mutateOrderAndEvents();
                    }}
                    mutateData={mutateOrderAndEvents}
                    patchShipmentAdviceDocument={(formData) =>
                      Axios.patch(
                        `/v1/storefronts/${storefront_id}/orders/${order.id}/documents/shipping_document`,
                        formData,
                        {
                          headers: { "Content-Type": "multipart/form-data" },
                        }
                      )
                    }
                    postShipmentAdvice={(formData) =>
                      Axios.post(
                        `/v1/storefronts/${storefront_id}/orders/${order.id}/shipment-advice`,
                        formData,
                        {
                          headers: { "Content-Type": "multipart/form-data" },
                        }
                      )
                    }
                    patchShipmentAdvice={(formData) =>
                      Axios.patch(
                        `/v1/storefronts/${storefront_id}/orders/${order.id}/shipment-advice/${adviceToEdit?.id}`,
                        formData,
                        {
                          headers: { "Content-Type": "multipart/form-data" },
                        }
                      )
                    }
                    deleteShipmentAdvice={(documentId) =>
                      Axios.delete(
                        `/v1/storefronts/${storefront_id}/orders/${order.id}/documents/${documentId}`
                      )
                    }
                  />
                </SlideOut>
              </Card>
            )}

            {order.alternative_po_number && !isNewOrder && (
              <Card>
                <PackingListSection
                  order={order}
                  readOnly={!canEdit}
                  mutateOrderAndEvents={mutateOrderAndEvents}
                />
              </Card>
            )}
            <SlideOut
              show={showAddDocumentForm}
              closeFlyout={() => setShowAddDocumentForm(false)}
            >
              <AddDocumentToTransaction
                existing_documents={get_existing_documents({
                  items: order.items,
                  seller_or_buyer_documents: order.seller_documents,
                })}
                products={order.items.map(
                  ({ product: { name }, product_id }) => ({
                    name,
                    id: product_id,
                  })
                )}
                transaction_type="orders"
                transaction_type_id={order_id}
                fetchData={mutateOrderAndEvents}
                onComplete={() => setShowAddDocumentForm(false)}
              />
            </SlideOut>

            <TransactionsDocumentView
              items={order.items}
              seller_documents={order.seller_documents}
              buyer_documents={order.buyer_documents}
              onAddDocumentClick={() => setShowAddDocumentForm(true)}
              status={order.status}
              buyer_role={order.buyer_role}
            />
          </QuoteOrderContent>
          <TimelineWrapper>
            {order && (
              <Timeline
                messages={orderEvents?.data ?? []}
                loggedInUser={user}
                fetchingData={isLoadingEvents}
                sendMessage={handleSendMessage}
                order={order}
                quote={quote}
              />
            )}
          </TimelineWrapper>
        </DetailPageContentWrapper>
      </PageWrapper>
    );
  } else return null;
});
