import type { IIconProps } from "../components/Icons/Icons";
import type { INavStyles } from "../layout/shared";
import type { FC, ReactNode } from "react";
import type { useForm, UseFormMethods } from "react-hook-form";
import type { DefaultTheme } from "styled-components";
import type { mutateCallback } from "swr/dist/types";
import type { MergeExclusive, ValueOf } from "type-fest";
import type { ChipType } from "../components/Chips/Chips";
import type {
  AttributeDataType,
  PIMProduct,
  ProductStatusType,
} from "./types.PIM";

// Type guards

/**
 * Typeguard for if value is defined, also works for undeclared values.
 * @param value
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return typeof value !== "undefined" && value !== null;
}

type EmptyString = "";
type EmptyArray = never[];
type EmptyObject = Record<string, never>;

type Empty = EmptyArray | EmptyObject | EmptyString;

/**
 * Typeguard for if value is empty. supports [], "" and {}.
 * @param subject
 */
export function isEmpty<T extends string | any[] | object>(
  subject: T | Empty
): subject is Bottom<T> {
  switch (typeof subject) {
    case "object":
      return Object.keys(subject).length === 0;
    case "string":
      return subject === "";
    default:
      return false;
  }
}

type Bottom<T> = T extends string
  ? EmptyString
  : T extends any[]
  ? EmptyArray
  : T extends object
  ? EmptyObject
  : never;

export function isChipTypeArray(value: any): value is ChipType[] {
  if (!isDefined(value)) return false;
  else if (isEmpty(value)) return false;
  else if (
    value.every((val: any) => Object.prototype.hasOwnProperty.call(val, "name"))
  ) {
    return true;
  } else return false;
}

export function isStringArray(value: any): value is string[] {
  if (!isDefined(value)) return false;
  else if (isEmpty(value)) return false;
  else if (value.every((val: any) => typeof val === "string")) return true;
  else return false;
}

// utility types:
/**
 * Make it explicit that that a component uses children
 *
 * usage:
 * ```
 * type DeletableChipProps = WithChildren<{
 *     active?: boolean;
 *     handleClick: (e: Event) => void;
 *   }>;
 *   ```
 */
export type WithChildren<T = {}> = T & { children?: React.ReactNode };

export type WithPagination<T = {}> = T & { pagination: IPagination };

export type OptionType<T = any> = {
  value: T;
  label: string;
};

/**
 * A little alias to make types more explicit.
 */
export type UUID = string;

/**
 * This is a string, just a nice alias to make things easier to read.
 * ISO 3166, Alpha2
 *
 * This corresponds to the `alpha_2` value in the @see ICountry type.
 * reference table:
 * {@link https://www.iban.com/country-codes}
 *
 */
export type Alpha2 = string;

/**
 * used in conjunction with `OptionType` with `react-select`. The __isNew__
 * boolean will be present only when using `CreatableSelectBox`
 */
export type CreatedOption<T = any> = {
  value: T;
  label: string;
  __isNew__?: boolean;
};

/**
 * Determine if the value is an option or created option.
 * This exists to guard against null value returns by react-select
 * @param maybeCreatedOption
 */
export function isCreatedOption(
  maybeCreatedOption: unknown
): maybeCreatedOption is CreatedOption {
  return (
    typeof maybeCreatedOption === "object" &&
    maybeCreatedOption !== null &&
    maybeCreatedOption?.hasOwnProperty("value") &&
    maybeCreatedOption?.hasOwnProperty("label")
  );
}

export interface UserConfigurableSEODetails {
  page_title: string;
  meta_title: string;
  meta_keywords: string;
  meta_description: string;
}

export interface ProductGroups {
  id: string;
  type: string;
  name: string;
  image_url: URL | null;
}

export interface Identifiers {
  molecular_formula: string | null;
  hs_code: string | null;
  synonyms: string | null;
  cas_number: string | null;
  ec_number: string | null;
  inchi_key: string | null;
  inci_name: string | null;
  chemical_name: string; // This can sometimes be "NA" or "N/A"
}

/**
 * Used for both product "property" and product "specification", which have
 * the same structure and are not likely to diverge.
 */
export interface ProductDetail {
  id?: string;
  unit: string;
  value: string;
  name: string;
}

/**
 * The type of the product application data that is attached to
 * products (product.product_applications).  It contains a `filter_id` but
 * `filter_id` is not currently documented in the API spec (2021/07/29).
 * For historical reasons the `filter_id` is what we want to use, not the `id`.
 * So we don't even include the `id` in the type, to prevent us from using it.
 *
 * To work around this issue and prevent making a bad situation worse, convert
 * this type to ProductApplication to fix the ID and convert into a corrected
 * type:
 *
 *    fixProductApplication(productApplicationBrokenId)
 *
 * Eventually it would be better if the backend just dropped the current `id`
 * property and renamed `filter_id` to `id`.
 *
 * @see ProductApplicationSchema
 */
export interface ProductApplicationBrokenId_DO_NOT_USE {
  // See comment above.
  // id: string;
  name: string;
  image_url: string | null;
  filter_id: string;
}

/**
 * The type of the product application data that is attached to a quote
 * item or quote request item (quoteItem.product_applications). It does not
 * contain a `filter_id`, but the `id` here is the same ID as the `filter_id`
 * on ProductApplication. (Even though the same ProductApplicationSchema
 * describes both sets of data.)
 * @see ProductApplicationSchema
 */
export interface ProductApplication {
  id: string;
  name: string;
  image_url: string | null;
}

export interface IPackagingType {
  name: string;
  id: string;
  description: string;
  // units_per_tl appears to be an unused hold over from version.
  units_per_tl: string | null;
}

/**
 * @see PackagingUnitSchema
 */
export interface IPackagingUnit {
  name: string;
  id: string;
}

/**
 * @see ProductSKUSchema
 * Existing products have `is_sample` initialized to null, which we are treating
 * as false in application code.
 *
 * The distributor_id is null for producer skus and will only have a value for
 * skus from a distributor.
 *
 */
export interface ProductSKU {
  id: string;
  distributor_id: string | null;
  erp_system_id: string;
  packaging_unit: IPackagingUnit;
  packaging_type: IPackagingType;
  package_description: string;
  package_volume: string;
  list_price: string;
  is_sample: boolean | null;
  buyer_id?: string;
  // 'Buyer SKU' is for custom SKUs created by a seller or a buyer, it has a
  // buyer ID property.
  // "Product SKU" is the standard SKU defined for a given product.
  // "No preference SKU" is used when the buyer selects "no preference" for a
  // SKU on a quote request. (see SkuNoPreference)
  kind: "Product SKU" | "Buyer SKU";
  number?: string;
}

/**
 * This type of SKU ( @see ProductSKU ) is created for a cart or quote request
 * item where the user chooses "No Preference" for the SKU.
 * The only useful thing in it is the `packaging_unit` which is how we know the
 * unit of measure for the total quantity on the quote request item.
 * It gets its own type to help prevent its nullability from infecting all
 * other uses of SKUs.
 * Issa read this
 */
export interface SkuNoPreference {
  buyer_id: null;
  distributor_id: string | null;
  erp_system_id: null;
  id: string;
  is_sample: null;
  list_price: string;
  // name: null;
  package_description: null;
  package_volume: null;
  packaging_type: null;
  packaging_unit: IPackagingUnit;
  kind: "No preference SKU";
  number?: string;
}

/**
 * Request body for POST:
 * /v1/tenants/{buyer_tenant_id}/products/{product_id_from_quote}/custom-skus
 *
 * @see TenantSKUCreationArgsSchema
 */
export interface TenantSkuCreationArgs {
  erp_system_id?: string;
  custom_packaging_name?: string;
  packaging_type_id: string;
  packaging_unit_id: string;
  package_volume: string;
  list_price?: string;
  package_description?: string;
}

/**
 * Response body for GET /v1/storefronts/{storefront_id}/products/packaging-types
 * @see PackagingTypePaginatedOutputSchema
 * Note the name of the schema includes "paginated" but there is no "paginated"
 * property, only "data", and no "limit" param.
 */
export interface PackagingTypePaginatedOutput {
  data: IPackagingType[];
}

/**
 * Response body for GET /v1/storefronts/{storefront_id}/products/packaging-units
 * @see PackagingUnitPaginatedOutputSchema
 * Note the name of the schema includes "paginated" but there is no "paginated"
 * property, only "data", and no "limit" param.
 */
export interface PackagingUnitPaginatedOutput {
  data: IPackagingUnit[];
}

/**
 * Included with products and also attached to quotes, orders, sample requests,
 * etc.
 *
 * Success response (201) for POST to:
 * /v1/storefronts/{storefront_id}/products/{id}/documents/{kind}
 *
 * @see DocumentSchema
 */
export interface ProductDocument {
  kind:
    | "sds"
    | "tds"
    | "misc"
    | "purchase_order"
    | "invoice"
    | "application_guide"
    | "marketing_documents"
    | "customer_tax_exemption";
  name: string;
  size: number; // kilobytes?
  signed_url: string;
  id: string;
  is_downloadable: boolean;
  language?: SupportedLanguage;
}

export interface TDSDocument {
  kind: string;
  name: string;
  size: number; // kilobytes?
  signed_url: string;
  id: string;
}

/**
 * Associated with Orders from GET /v1/storefronts/{storefront_id_or_slug}/orders/{id}
 *
 * @see OrderDocumentSchema
 */
export type OrderDocument = {
  kind:
    | "invoice"
    | "shipping_document"
    | "purchase_order"
    | "other"
    | "packaging_list";
  name: string;
  size: number; // kilobytes?
  signed_url: string;
  id: string;
};

export type TransactionDocument = ProductDocument & {
  product_name: string;
  is_all_product: boolean;
  language: SupportedLanguage;
};

export type IExternalIdKind = "sap" | "ulp" | "salesforce";

/**
 * @see ExternalIdSchema
 */
interface IExternalId {
  external_id: string;
  kind: IExternalIdKind | null;
}

/**
 * @see SEODetailSchema
 */
export type SEODetail = {
  meta_description: string | null;
  meta_keywords: string | null;
  meta_title: string | null;
  page_title: string | null;
  og_title: string | null;
  og_type: string | null;
  og_image: string | null;
  og_description: string | null;
  og_locale: string | null;
  og_url: string | null;
};

// kind for orderDocuments = "invoice" | "shipping_document"
// invoice, shipping_document, purchase_order, other
// pdf doc txt json csv png jpeg doc dox svg webp

export type ProductLink = {
  id: UUID;
  url: string;
  name: string;
  is_visible: boolean;
};

/**
 * @see MasterProductSchema
 * @see SellerProductSchema
 */
export type Product = PIMProduct;

// {
//   slug: string;
//   safety_datasheet: string | null;
//   created_at: Date;
//   sold_by: string;
//   technical_datasheet: string | null;
//   meta_description: string | null;
//   functions: ProductTag[];
//   market_segments: ProductTag[];
//   industries: ProductTag[];
//   value_propositions: ValueProposition[];
//   product_groups: ProductGroups[];
//   identifiers: Identifiers | null;
//   meta_keywords: string | null;
//   produced_by: string | null;
//   product_properties: ProductDetail[];
//   product_specifications: ProductDetail[];
//   master_product: MasterProduct;
//   product_applications: ProductApplicationBrokenId_DO_NOT_USE[];
//   description: string;
//   brand_name: string;
//   meta_title: string;
//   id: string;
//   name: string;
//   is_active: boolean;
//   product_skus: ProductSKU[];
//   documents: ProductDocument[];
//   links: ProductLink[];
//   image_url: string; // Data from the backend may be an empty string ("").
//   is_featured: boolean | null;
//   external_id: IExternalId;
//   is_contracted_product: boolean | null;
//   seo_detail: SEODetail | null;
//   allow_custom_sku: boolean;
//   modified_at: string;
//   modified_by: string;
//   last_modified_full: LastModified;
// };

export interface LeadCartItem {
  packaging_type_id: string;
  packaging_unit_id: string;
  product: {
    name: string;
    identifiers?: Identifiers;
    produced_by?: string | null;
  };
  product_applications: ProductApplication[];
  total_volume: string;
}

export interface LeadCart {
  id: string;
  items: LeadCartItem[];
}

export interface IAddressInfo {
  city: string;
  country: string;
  state: string;
  postal_code: string;
  address1: string;
  address2?: string | null;
}

/**
 * A partial address used only with leads.
 */
export type LeadAddress = IAddressInfo & {
  state_display_name: string;
  county?: string;
};

/**
 * @see LeadNoteSchema
 */
export type LeadNote = {
  id: UUID;
  message: string;
  creator_name: string;
  is_system_generated: boolean;
  created_at: string;
};

export type RejectionNote = {
  created_at: string;
  created_by: string;
  creator_name: string;
  id: UUID;
  internal_message: string | null;
  message_for_buyer: string | null;
  modified_at: string;
  modified_by: string;
};

export type LeadRejectionArgsSchema = {
  internal_message: string | null;
  message_for_buyer: string | null;
  send_email_to_buyer: boolean;
};

export type LeadAssignee = {
  id: UUID;
  firstname: string;
  lastname: string;
  email_address: string;
};

/**
 * This is the response for the GET registration/contact-us requests
 */
export interface Lead {
  buyer_company_name: string;
  buyer_email: string;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_phone_number: string;
  cart: LeadCart;
  created_at: string;
  id: string;
  assignee_id: UUID;
  assignee: LeadAssignee | null;
  assignment_date: string | null;
  modified_at: string;
  seller_id: string;
  status: LeadStatus;
  extra?: {
    message?: string;
    custom_choices?: Record<string, string | null>;
  };
  parent_lead_id: string;
  children: Lead[];
  address: LeadAddress | null;
  number: string;
  source: "contact_form" | "registration" | "quote_request";
  is_existing_customer: boolean;
  company_name: string;
  customer_id: string;
  parent_customer_id: string;
  shipping_address?: IAddress;
  billing_address?: IAddress;
  required_eta?: string;
  internal_notes: LeadNote[];
  rejection_note?: RejectionNote;
  region?: string;
}

/**
 * There may or may not be production data with this type, however new features should not be built to use it.
 * @deprecated
 */
export type DeprecatedLeadStatus = "Lead Declined";

/**
 * Do not use "Lead Declined" in new features
 */
export type LeadStatus =
  | "New"
  | "Forwarded"
  | "Converted"
  | "Accepted"
  | "Added as User"
  | "Rejected"
  | DeprecatedLeadStatus;

/**
 * Success response (201 & 208) for POST to
 * /v1/storefronts/${storefront_id}/leads
 * A 201 response is a LeadSchema (has `source` property).
 * A 208 response is a LeadUserExistsSchema (has `redirect_url`).
 * This type is for both with `source` and `redirect_url` as optional.
 * @see ILeadSchema
 * @see LeadUserExistsSchema
 */
export interface ILeadSchema {
  id: string;
  cart: LeadCart;
  buyer: IBuyer;
  status: string; // TODO add enum.
  created_at: string;
  modified_at: string;
  source?: string;
  redirect_uri?: string;
}

/**
 * despite the name of the schema these are in fact quote request leads. This is
 * a bit of a historical quirk because originally all leads were "proto quote requests".
 * @see LeadPaginatedOutputSchema
 */
export type QuoteRequestLeadPaginatedOutput = WithPagination<{
  data: Lead[];
}>;

interface IBuyer {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_name: string;
}

export type FilterGroup = {
  count: number;
  image_url: string | null;
  name: string;
  selected: boolean;
  id?: number;
};

export type FilterType =
  | "application"
  | "function"
  | "industry"
  | "group1"
  | "group2"
  | "group3"
  | "market_segment"
  | "proposition"
  | "produced_by"
  | "product_line";

export type FilterLabel = {
  language: SupportedLanguage;
  label: string;
};

/**
 * despite the name this is the response body from the custom-labels endpoint.
 * It has been slightly overloaded to include more related information to
 * control how the product detail page is displayed.
 */
export type TagClassificationConfig = {
  filter_type: string;
  filter_labels: FilterLabel[];
  is_visible: boolean;
};

/**
 * group1, group2, group3 and selected are legacy from 2.0.
 *
 */
export type Filters = Record<string, (FilterGroup & { name: string })[]>;

export type ProductFiltersMainSchema = {
  filters: Filters;
};

export type StorefrontEdition = "starter" | "business" | "enterprise" | "pim";

export type TenantType = "Distributor" | "Buyer" | "Producer" | "Admin";

export type PolicyDocument = {
  kind: "privacy_policy" | "terms_of_sale";
  name: string;
  size: number;
  signed_url: string;
  id: string;
};

export type CustomFont = {
  font_url: string;
  font_family_name: string;
};

export type HomepageSettings = {
  mx_width: string | undefined;
  nav_settings: {
    [key: string]: INavStyles;
  };
};

/**
 * @see LeadTransactionVisibility
 * server/src/agilis/models/constant.py
 *
 */
export type LeadTransactionVisibility = "enabled" | "disabled" | "hidden";

export type DefaultPublicNavRoute =
  | "contact_us"
  | "registration"
  | "portfolio"
  | "home";

export type PublicNavRouteConfig = {
  route: DefaultPublicNavRoute;
  enabled: boolean;
  translations: Record<SupportedLanguage, string>;
};

export type RouteConfiguration = PublicNavRouteConfig[];

export type ConfigureCheckboxItem = {
  id: UUID;
  name: string;
  quote: boolean;
  sample: boolean;
  contact: boolean;
  registration: boolean;
};

export type PortfolioViewType = "classic" | "modern" | "grid";

export type SsoProviderType = "ADFSSAML" | "GoogleOAuth";

/**
 * /v1/storefronts/{storefront_id_or_slug}/metadata
 */
export type StorefrontMetaData = {
  // The `id` is the storefront ID.
  id: string;
  header: string;
  accepts_payments: boolean;
  sub_header?: string;
  sender_name?: string;
  placeholder: string;
  browser_title: string;
  tenant_name: string;
  metadata_localization: StoreFrontMetadataLocalization;
  tenant_type: TenantType | null;
  tenant_id: string;
  edition: StorefrontEdition;
  sample_requests_visibility: LeadTransactionVisibility;
  quote_requests_visibility: LeadTransactionVisibility;
  policy_documents: PolicyDocument[];
  configured_checkboxes: ConfigureCheckboxItem[];
  google_analytics_key: string | null;
  enable_invoice_auto_generation: boolean;
  enable_transaction_lead_quote: boolean;
  enable_transaction_lead_sample: boolean;
  enable_order_auto_accept: boolean;
  enable_vertex_ai_search: boolean;
  unlisted_product_requests_enabled: boolean;
  host: string | null;
  slug: string | null;
  release_version: string;
  custom_font?: CustomFont | undefined;
  theme_object: {
    branding: {
      brandColor: string;
      portalLogoUrl: string;
      publicLogoUrl: string;
      customFontFamily: string;
      favIconUrl: string;
    };
  };
  homepage_settings?: HomepageSettings | undefined;
  custom_domain?: string;
  custom_pages?: CustomPageConfig[];
  digital_marketing_enabled: boolean;
  mandatory_customer_creation_form: boolean;
  default_currency: CurrencyCode;
  default_language: SupportedLanguage;
  use_custom_packaging: boolean;
  use_custom_privacy_policy: boolean;
  use_custom_terms_of_sale: boolean;
  supported_languages: { [key: string]: string };
  supported_languages_localized: { [key: string]: string };
  email_preferences_enabled: boolean;
  enable_product_selector: boolean;
  is_product_selector_enabled: boolean;
  product_selector_csv_upload_enabled: boolean;
  product_selector_form_inputs_enabled: boolean;
  product_selector_csv_document: ProductSelectorCSVDocument | null;
  route_configuration: RouteConfiguration;
  is_contact_message_compulsory: boolean;
  default_portfolio_view: PortfolioViewType;
  show_privacy_policy_on_contact_forms: boolean;
  sso_provider: SsoProviderType | null;
  sso_domain: string | null;
  sso_only: boolean;
  custom_support_url: string | null;
  totp_required: boolean;
  only_bulk_upload_enabled: boolean;
  is_chatbot_configured: boolean;
  enable_email_reminder: boolean;
};

export type StorefrontFormData = {
  data: {
    Contact: {
      Description: string;
    };
    Register: {
      Description: string;
    };
  };
  language: SupportedLanguage;
};

export type SingleStorefrontLocalization = {
  browser_title: string;
  header: string;
  placeholder: string;
  is_active: boolean;
  sub_header: string;
  // localization_name: string;
};

/**
 * This is an object with keys based on ISO 639-1 or null. The reason we don't
 * have a union of supported languages is that it would require code changes
 * everytime a new one is added to stay accurate. Any language should be drop in supported here
 * although rtl languages would require additional changes to look good.
 */
export type StoreFrontMetadataLocalization = {
  [key: string]: SingleStorefrontLocalization;
} | null;

export interface CustomPageConfig {
  path: string; // e.g. "/home"
  // browser_title may actually still exist but it is deprecated
  // browser_title: string;
  nav_link_text: string | null;
  nav_link_icon: string | null;
  layout_omit_user_area: boolean;
  layout_omit_header: boolean;
  layout_full_width: boolean;
  is_system: boolean;
  priority: number;
  page_banner: {
    title: string | null;
    index: number;
    image_url: string;
    link_url: string | null;
    caption: string | null;
  }[];
  page_banner_settings: {
    fixed_overlay: boolean;
    title: string;
    caption: string;
    link_url: string;
  } | null;
  // A custom page may be translated into different languages, each translation
  // has its own `id` that is used to fetch the html and css for the page in
  // that language when the user navigates to that page.
  page_content: {
    id: string;
    browser_title: string;
    language: SupportedLanguage; // For example: "de", "en", "fr", "hi", "ja", "zh"
    nav_link_text: string;
    is_active: boolean;
  }[];
}

export type CurrencyCode = "USD" | "CNY" | "JPY" | "CAD" | "EUR";

/**
 * @example { code: "USD", name: "US Dollar", symbol: "$" }
 */
export type Currency = {
  code: CurrencyCode;
  name: string;
  symbol: string;
};

/**
 * Response body for GET /v1/storefronts/{storefront_id}/metadata
 */
export type APIMetaData = {
  default_logo_url: string;
  default_redirect: string;
  default_from_email: string;
  error_logging_api_key: string;
  release_version: string;
  supported_currencies: Currency[];
  tenant_slug: string;
};

/**
 * These are the values of `user.user_type`. Some date from v2 and are needed
 * for backwards compatibility with v2. Others are used in v3 and  are
 * equivalent to the `user.rbac_role` values.
 * TODO what is the continued purpose of this?
 */
export type UserType =
  // v2 types - deprecated
  | "Primary Contact"
  | "Master Admin"
  | "Standard User"
  // v3 types
  | "Agilis Admin"
  | "Buyer Admin"
  | "Distributor Admin"
  | "Buyer Standard"
  | "Seller Admin"
  | "Seller Standard"
  | "Distributor";

/**
 * Used to show chips (options) when creating or editing a user account.
 * The `id` gets sent to the backend and `name` is shown in the UI.
 */
export type UserTypeChip =
  | { name: "Admin"; id: "Seller Admin" | "Buyer Admin" }
  | { name: "Distributor"; id: "Distributor Admin" }
  | { name: "Standard"; id: "Seller Standard" | "Buyer Standard" };

export type RBACRole =
  | "buyer_admin"
  | "buyer_standard"
  | "seller_admin"
  | "distributor"
  | "distributor_admin"
  | "seller_standard"
  | "guest";

/**
 * These typically control whether or not the route itself is visible on the
 * navbar, but may also eventually control the presence of tabs or subsections
 * of pages.
 */
export type ViewPermissions =
  | "view_lists"
  | "view_attributes"
  | "view_collections"
  | "view_groups"
  | "view_products"
  | "view_templates"
  | "view_assets";

/**
 * modify is always "create" AND "update"/"edit"
 * A user can never create something but not modify it.
 * controls wether creation/edit buttons are clickable.
 */
export type ModifyPermissions =
  | "modify_attributes"
  | "modify_lists"
  | "modify_groups"
  | "modify_collections"
  | "modify_products"
  | "modify_templates"
  | "modify_assets";

export type DeletePermissions =
  | "delete_lists"
  | "delete_attributes"
  | "delete_groups"
  | "delete_collections"
  | "delete_templates"
  | "delete_products"
  | "delete_assets";

export type Permission =
  | ViewPermissions
  | ModifyPermissions
  | DeletePermissions;

export type TwoFactorTypes = "totp" | "sms" | "voice";

export interface TOTPSchema {
  has_totp_key: boolean;
  preferred_2fa_type: TwoFactorTypes | null;
  totp_last_authorized: string | null;
  totp_login_required: boolean;
  sms_2fa_phone_number: string | null;
}

interface UserBase extends TOTPSchema {
  company_name: string;
  firstname: string;
  id: string;
  lastname: string;
  phone_number: string;
  email_address: string;
  position: string;
  tenant_id: string;
  user_type: UserType;
  username: string;
  created_at: string;
  is_active: boolean;
  role: { id: UUID; name: string };
  seller_id: string | null;
  settings: ITenantCustomerSettings | null;
  preferred_language: SupportedLanguage;
  quote_threshold_usd: number | null;
  order_threshold_usd: number | null;
  quote_threshold_local: number | null;
  order_threshold_local: number | null;
  is_primary_contact?: boolean;
  is_point_of_contact?: boolean;
  point_of_contact_id?: string;
  chat_email_notification_enabled: boolean;
  is_buyer_activated: boolean;
  self_actions_email_notification_enabled: boolean;
  team_actions_email_notification_enabled: boolean;
  products_view_mode: "list" | "portfolio" | "modern";
  email_notifications: {
    storefront_id: UUID;
    // leads
    leads: boolean;
    leads_enable_quote_request: boolean;
    leads_enable_sample_request: boolean;
    leads_enable_registration: boolean;
    leads_enable_contact_us: boolean;
    // transactions
    transactions: boolean;
    transactions_enable_quote_request: boolean;
    transactions_enable_order: boolean;
    transactions_enable_sample_request: boolean;
  }[];
  crm_id?: string;
  is_sso_login: boolean;
  tenant_user_contact_info?: AccountManagerContactInfo;
}

interface SellerUser extends UserBase {
  rbac_role: Extract<RBACRole, "seller_admin" | "seller_standard">;
  permissions_list: Permission[];
}

interface BuyerUser extends UserBase {
  rbac_role: Exclude<RBACRole, "seller_admin" | "seller_standard">;
  permissions_list: never;
}

/**
 * Response for GET to:
 * /v1/storefronts/{storefront_id_or_slug}/users/login
 *
 * @see AgilisUserLoginSchema
 * Note this API schema needs to be updated to include email_address,
 * created_at, and is_active to match what the backend code is sending.
 *
 * (There is also the similar @see AgilisUserSchema and we may eventually want
 * to have a separate type for that.)
 *
 * The `user_type` is mostly a v2 thing that is superseded by RBAC roles.
 * It's primary purpose in v3 is for backwards compatibility with v2. However,
 * when creating or editing a user account, the back end takes a user_type
 * and uses that to calculate the rbac_role. The two categories are basically
 * equivalent for new v3 user accounts.
 *
 * For a buyer user the `seller_id` is the seller for that buyer on the
 * current storefront. That seller can be the storefront tenant (e.g. a
 * producer) or an invited distributor selling chemicals on that storefront.
 * For non invited distributor seller users the seller_id is the same as
 * the tenant_id.
 *
 *
 * Temporarily RBAC permissions only applies to sellers. Hopefully this code
 * doesn't last very long.
 */
export type User = SellerUser | BuyerUser;

/**
 * Response body for GET to:
 * /v1/storefronts/{storefront_id_or_slug}/tenants/{tenant_id}/users
 * @see AgilisUserPaginatedOutputSchema
 */
export type AgilisUsersPaginatedOutput = WithPagination<{
  data: User[];
}>;

/**
 * a potential request body for POST /v1/storefronts/{storefront_id_or_slug}/tenants/{id_or_slug}/users
 * if the user_type is "Standard Seller" other fields are required.
 * @see AgilisUserCreationArgsSchema
 */
export type AgilisUserCreationArgs = {
  email_address: string;
  firstname: string;
  lastname: string;
  phone_number: string;
  user_type: UserType;
  preferred_language: SupportedLanguage;
};

/**
 * The request body for POST /v1/storefronts/{storefront_id_or_slug}/tenants/{id_or_slug}/users
 * in the case the the `user_type` = "Standard Seller"
 * @see AgilisUserCreationArgsSchema
 */
export type AgilisUserCreationsSellerStandardArgs = AgilisUserCreationArgs & {
  user_type: "Seller Standard";
  // user_role: "seller_standard";
  quote_threshold_usd: number | null;
  order_threshold_usd: number | null;
  quote_threshold_local: number | null;
  order_threshold_local: number | null;
};

/**
 * The request body for PATCH /v1/storefronts/{storefront_id_or_slug}/tenants/{id_or_slug}/users/{user_id}
 * Currently the role_id param is required for sellers but is not needed for
 * other user types.
 * @see AgilisUserPatchArgsSchema
 */
export interface IAgilisUserPatchArgs {
  email_address?: string;
  firstname?: string;
  lastname?: string;
  crm_id?: string;
  phone_number?: string;
  preferred_language: SupportedLanguage;
  user_type?: UserType;
  role_id?: UUID;
  is_active?: boolean;
  quote_threshold_usd?: number | null;
  order_threshold_usd?: number | null;
  quote_threshold_local?: number | null;
  order_threshold_local?: number | null;
  email_notifications?: {
    storefront_id: UUID;
    // leads
    leads: boolean;
    leads_enable_quote_request: boolean;
    leads_enable_sample_request: boolean;
    leads_enable_registration: boolean;
    leads_enable_contact_us: boolean;
  };
}

export type IPagination = {
  offset: number;
  order_by: "desc" | "asc";
  total: number;
  limit: number;
};

type AddressTypes =
  | "Headquarters Address"
  | "Payer Address"
  | "Sold To Address"
  | "Warehouse";

/**
 * because company_name was a later addition many existing addresses will
 * not have it.
 * @see AddressSchema
 */
export interface IAddress extends IAddressInfo {
  contact_last_name: string;
  contact_first_name: string;
  id: string;
  company_name: string | null;
  tax_id: string | null;
  crm_id: string | null;
  phone_number: string;
  email_address: string;
  external_identity: IExternalId;
  state_display_name: string;
  subdivision_name?: string;
  county: string | null;
  type: AddressTypes;
  contact_name: string;
  is_active: boolean;
  point_of_contact_id: string;
  same_as_hq?: boolean;
}

export interface Tenant extends IAddressInfo {
  primary_contact_id?: string;
  id: string;
  name: string;
  doing_business_as: string;
  external_identity: IExternalId;
  type: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  distributors: string[] | null; // array of hashes
  producers: string[]; // array of hashes
  addresses: IAddress[];
  market_segment: string[];
  industries: IIndustry[];
  point_of_contact_id: string;
  crm_id?: string;
  primary_contact: {
    full_name: string;
    first_name: string;
    last_name: string;
    email_address: string;
    phone_number: string;
    preferred_language: SupportedLanguage;
  };
  // This can be null because it was introduced well after this endpoint was
  // first built.
  modified_at: string | null;
}

interface IIndustry {
  id: string;
  name: string;
  image_url: string;
}

/**
 * /v1/storefronts/{storefront_id_or_slug}/tenants/{id_or_slug}/storefront/theme
 */
export interface ThemeResponse {
  id: string;
  tenant_id: string;
  theme_object: DefaultTheme;
}

/**
 * POST /v1/storefronts/${storefront_id}/leads/contact-requests
 * @see LeadCreationContactRequestArgsSchema
 */
export interface IContactRequest {
  seller_id_or_slug: string;
  message: string | undefined;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_email: string;
  buyer_phone_number: string;
  buyer_company_name: string;
  language: SupportedLanguage;
  custom_choices?: Record<string, string | null>;
}

export interface IRegistrationRequest {
  seller_id_or_slug: string;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_email: string;
  buyer_phone_number: string;
  buyer_company_name: string;
  language: SupportedLanguage;
  custom_choices?: Record<string, string | null>;
}

export interface IRegistrationRequestWithAddress extends IRegistrationRequest {
  address: IAddressInfo & { county: ValueOf<Pick<IAddress, "county">> };
}
/**
 * GET /v1/storefronts/{id}/lead-configuration
 * TODO: add reference to backend schema object.
 *
 * `default_country` refers to the actual country, and `default_country_code` is
 *  used to look up the correct country code prefix e.g. "+1" for United States.
 */
export type LeadConfiguration = {
  show_location_form: boolean;
  default_country: Alpha2;
  default_country_code: Alpha2;
};

/**
 * POST /v1/storefronts/${storefront_id}/leads/contact-requests
 * @see LeadCreationContactRequestArgsSchema
 * @see LeadConfiguration
 * Special extension of the contact request schema for storefronts that have
 * optionally configured to have a partial address included in the lead for ease
 * of routing to distributors by location.
 */
export interface IContactRequestWithAddress extends IContactRequest {
  address: IAddressInfo & { county: ValueOf<Pick<IAddress, "county">> };
}

/**
 * Response for:
 * GET /v1/storefronts/{storefront_id_or_slug}/tenants
 * GET /v1/storefronts/{storefront_id_or_slug}/distributors
 * @see TenantPaginatedOutputSchema
 */
export interface ITenantPaginatedOutputResponse {
  data: Tenant[];
  pagination: IPagination;
}

/**
 * GET /v1/storefronts/{storefront_id_or_slug}/products/{product_id}/privacy
 */
export interface ISellerProductPrivacyResponse {
  id: string;
  is_private: boolean;
  visible_tenants: Tenant[];
}

/**
 * PATCH /v1/storefronts/{storefront_id_or_slug}/products/{product_id}/privacy
 */
export interface ISellerProductPrivacyArgs {
  is_private: boolean;
  tenants_to_add?: string[]; // array of tenant IDs
  tenants_to_delete?: string[]; // array of tenant IDs
}

/**
 * GET /v1/storefronts/{storefront_id}/products
 */
export interface IMasterProductPaginatedOutput {
  data: Product[];
  pagination: IPagination;
}

/**
 * Response body for GET /v1/storefronts/{id_or_slug}/products
 * @see SellerProductPaginatedOutputSchema
 */
export interface ISellerProductPaginatedOutput {
  data: Product[];
  pagination: IPagination;
}

/**
 * @see PaymentTermSchema
 */
export interface IPaymentTerm {
  id: string;
  name: string;
}

/**
 * Response body for:
 * GET /v1/storefronts/{storefront_id}/payment-terms
 * GET /v1/commerce/payment-terms
 * @see PaymentTermPaginatedOutputSchema
 */
export type PaymentTermPaginatedOutput = WithPagination<{
  data: IPaymentTerm[];
}>;

/**
 * @see PaymentModeSchema
 */
export interface IPaymentMode {
  id: string;
  name: string;
  payments_enabled: boolean;
}

export interface IMessageRequestJson {
  message?: string;
  message_type?: string;
  required_eta?: string;
  no_of_units?: string;
  is_tax_exempt?: string;
  valid_to_date?: string;
  fees_to_add?: Fee[];
  fees_to_overwrite?: Fee[];
  price_per_unit?: string | null;
  alternative_po_number?: string | null;
  kind?: string | null;
}

/**
 * Response body for:
 * GET /v1/storefronts/{storefront_id}/payment-modes
 * GET /v1/commerce/payment-modes
 *
 * @see PaymentModePaginatedOutputSchema
 */
export type PaymentModePaginatedOutput = WithPagination<{
  data: IPaymentMode[];
}>;

/**
 * @see DeliveryTermSchema
 */
export interface IDeliveryTerm {
  id: string;
  name: string;
  description: string;
}
/**
 * Response body for:
 * GET /v1/storefronts/{storefront_id}/delivery-terms
 * GET /v1/commerce/delivery-terms
 * @see DeliveryTermPaginatedOutputSchema
 */
export type DeliveryTermPaginatedOutput = WithPagination<{
  data: IDeliveryTerm[];
}>;

/**
 * Request body for POST to:
 * /v1/storefronts/{storefront_id_or_slug}/unified-cart:
 *
 * @see UnifiedCartArgSchema
 */
export type UnifiedCartArg = {
  product_id: string | null;
  unlisted_product_name?: string;
  applications?: string[];
  custom_application?: string;
  seller_id?: string;
  sku_id?: string;
  no_of_units?: string;
  price_per_unit?: string;
  currency?: CurrencyCode;
  shipping_address_id?: string;
  delivery_term_id?: string;
  payment_term_id?: string;
  payment_mode_id?: string;
  order_id?: string;
  item_id?: string;
  total_quantity?: string;
  packaging_unit_id?: string;
  custom_packaging_type_id?: string;
  custom_packaging_quantity?: string;
  buyer_id?: string;
};

/**
 * Request body for PATCH to:
 * /v1/storefronts/{storefront_id_or_slug}/unified-cart/items/{item_id}:
 *
 * @see UnifiedCartItemPatchArgsSchema
 */
export type UnifiedCartItemPatchArgs = {
  no_of_units?: string;
  price_per_unit?: string;
  currency?: CurrencyCode;
  applications?: string[];
  custom_application?: string;
  sku_id?: string;
  total_quantity?: string;
  packaging_unit_id?: string;
  custom_packaging_type_id?: string;
  custom_packaging_quantity?: string;
  buyer_id?: string;
};

/**
 * Response body for GET /v1/storefronts/{storefront_id_or_slug}/quotes
 * @see QuoteRequestPaginatedOutputSchema
 */
export interface IQuotesAPIResponse {
  data: IQuoteRequest[];
  pagination: IPagination;
}

/**
 * Response body for POST /v1/storefronts/{storefront_id_or_slug}/quotes
 * Response body for POST /v1/storefronts/{storefront_id_or_slug}/quotes/{id}
 * @see QuoteRequestSchema
 */
export interface IQuoteRequest {
  id: string;
  payment_term: IPaymentTerm;
  payment_mode: IPaymentMode;
  delivery_term: IDeliveryTerm;
  shipping_address: IAddress;
  required_eta: string; // date-time string, e.g. "2020-08-01T17:32:28Z"
  items: QuoteRequestItem[];
  modified_at: string;
  fees: Fee[];
  total_fees: string;
  valid_to_date: string;
  seller_id: string;
  seller_name: string;
  buyer_id: string;
  buyer_name: string;
  status: QuoteStatus;
  number: string;
  seller_address: IAddress;
}

/**
 * Request body for POST /v1/storefronts/{storefront_id_or_slug}/quotes
 * A POST to that endpoint creates a new cart and adds one item to it.
 * @see QuoteRequestCreationArgsSchema
 *
 * The properties:
 * - shipping_address_id
 * - delivery_term_id
 * - payment_term_id
 * Are optional here so they match the schema on the backend, but on the
 * frontend we typically always create quote requests by supplying these
 * properties. (Verify that in the code in case this comment is outdated.)
 */
export interface IQuoteRequestCreationArgs {
  seller_id: string;
  product_id: string;
  sku_id?: string;
  no_of_units?: string;
  price_per_unit?: string | null;
  currency?: CurrencyCode;
  applications?: string[];
  shipping_address_id?: string;
  delivery_term_id?: string;
  payment_term_id?: string;
  payment_mode_id?: string;
}

/**
 * Request body for PATCH /v1/storefronts/{storefront_id_or_slug}/quotes/{quote_id}
 * Request body for PATCH /v1/storefronts/{storefront_id_or_slug}/quotes/{quote_id}/respond
 * Used when an existing user (e.g. buyer) submits a cart.
 *
 * fees_to_remove is an array of UUIDs.
 *
 * fees_to_add and fees_to_remove are considered legacy. fees_to_overwrite
 * is easier to work with and should be used going forward. The other two
 * are only here to keep the type accurate.
 *
 * @see QuoteRequestPatchArgsSchema
 */
export interface QuoteRequestPatchArgs {
  delivery_term_id?: string;
  payment_term_id?: string;
  payment_mode_id?: string;
  shipping_address_id?: string;
  required_eta?: string;
  valid_to_date?: string;
  is_tax_exempt?: boolean;
  fees_to_add?: Fee[];
  fees_to_overwrite?: Fee[];
  fees_to_remove?: string[];
}

export interface QuoteRequestOrOrderItem {
  id: string;
  total_quantity: string;
  price_per_unit: string | null;
  currency: CurrencyCode;
  product_applications: ProductApplication[];
  documents: ProductDocument[];
  last_purchase: LastPurchase;
}

/**
 * Response body for POST /v1/storefronts/{storefront_id_or_slug}/quotes/{id}/items
 * Response body for PATCH /v1/storefronts/{storefront_id_or_slug}/quotes/{quote_id}/items/{item_id}
 * @see QuoteRequestDetailSchema
 */
export interface QuoteRequestItem {
  id: string;
  total_quantity: string;
  requested_total_quantity: string;
  no_of_units: string;
  requested_no_of_units: string;
  custom_packaging_quantity: string;
  price_per_unit: string | null;
  currency: CurrencyCode;
  sku: ProductSKU | SkuNoPreference;
  requested_sku: ProductSKU | SkuNoPreference;
  product_id: string;
  product_applications: ProductApplication[];
  custom_application?: string;
  product_identity: {
    cas_number: string;
  };
  product: {
    name: string;
    identifiers?: Identifiers;
    produced_by?: string | null;
    slug?: string;
  };
  documents: ProductDocument[];
  last_purchase: LastPurchase;
}

/**
 * Request body for POST /v1/storefronts/{storefront_id_or_slug}/quotes/{id}/items
 * @see QuoteRequestItemCreationArgsSchema
 */
export interface IQuoteRequestItemCreationArgs {
  product_id: string;
  sku_id?: string;
  no_of_units?: string;
  total_quantity?: string;
  packaging_unit_id?: string;
  custom_packaging_type_id?: string;
  custom_packaging_quantity?: string;
  price_per_unit?: string | null;
  currency: CurrencyCode;
  applications: string[]; // UUID strings
  buyer_id?: string;
}

/**
 * Request body for PATCH /v1/storefronts/{storefront_id_or_slug}/quotes/{quote_id}/items/{item_id}
 * @see QuoteRequestItemPatchArgsSchema
 */
export interface IQuoteRequestItemPatchArgs {
  sku_id?: string;
  no_of_units?: string;
  total_quantity?: string;
  packaging_unit_id?: string;
  custom_packaging_type_id?: string;
  custom_packaging_quantity?: string;
  price_per_unit?: string;
  currency?: CurrencyCode;
  applications?: string[];
  custom_application?: string;
  buyer_id?: string;
  requested_sku_id?: string;
  requested_no_of_units?: string;
  requested_total_quantity?: string;
}

export type QuoteStatus =
  | "new"
  | "requested"
  | "responded"
  | "pending"
  | "cancelled"
  | "declined"
  | "accepted"
  | "pending_activation"
  | "expired";

export type QuoteStatusProps = {
  status: QuoteStatus;
  position?: "right" | "left";
  id?: string;
  t?: (s: string) => string;
};

export interface StatusAndId<T> {
  status: T;
  id: string;
}

/**
 * @see ChargeDiscountSchema
 * This has an `id` and is used when retrieving from server.
 *
 * @see ChargeDiscountArgsSchema for the version of this without the `id` that
 * is used for creation.
 */
export interface Fee {
  id: string;
  name: string;
  amount: number;
  currency: CurrencyCode;
}

/**
 * GET /v1/storefronts/{storefront_id_or_slug}/quotes/{id}
 * @see QuoteRequestSchema
 */
export interface QuoteRequest {
  id: string;
  created_at: string; // date
  created_by: string;
  payment_term: IPaymentTerm;
  payment_mode: IPaymentMode;
  delivery_term: IDeliveryTerm;
  shipping_address: IAddress;
  billing_address: IAddress;
  required_eta: string;
  modified_at: string;
  modified_by: string;
  number: string;
  items: QuoteRequestItem[];
  seller_documents: TransactionDocument[];
  buyer_documents: TransactionDocument[];
  fees: Fee[];
  total_fees: string;
  valid_to_date: string;
  auto_accept: boolean;
  seller_id: string;
  seller_name: string;
  buyer_id: string;
  buyer_name: string;
  buyer_role: "buyer" | "distributor";
  status: QuoteStatus;
  is_tax_exempt: boolean;
  seller_address: IAddress;
  currency: CurrencyCode;
  payments?: Payment;
  sold_by?: User;
  created_by_company_name?: string;
}

export interface IOrdersAPIResponse {
  data: IPurchaseOrder[];
  pagination: IPagination;
}

/**
 * @see OrderStatus
 */
export type OrderStatus =
  | "new"
  | "in_progress"
  | "accepted"
  | "invoiced"
  | "completed"
  | "declined"
  | "cancelled"
  | "payment_received"
  | "shipped"
  | "completed"
  | "closed"
  | "back_ordered"
  | "pending"
  | "pending_activation";

export type OrderStatusLabel =
  | "Placed"
  | "Accepted"
  | "In Progress"
  | "Invoiced/Payment Received"
  | "Shipped"
  | "Completed"
  | "Closed"
  | "Declined"
  | "Cancelled"
  | "Payment Received"
  | "Back Ordered"
  | "Pending Activation";

/**
 * Order status action to be sent to the BE to update the Order status
 */
export type OrderStatusAction = Exclude<
  PurchaseOrderAction["purchase_order_action"],
  null
>;

/**
 * @see ShipmentAdviceSchema
 */
export interface IShipmentAdvice {
  id: UUID;
  additional_comments: string | null;
  carrier: string | null;
  carrier_number: string | null;
  estimated_time_of_arrival: string | null;
  estimated_time_of_dispatch: string | null;
  shipment_advice_type: string | null;
  shipment_advice_document: OrderDocument;
}

export type LotNumber = {
  id?: UUID;
  item_id: UUID;
  number: string;
  units: number;
};

/**
 * Response body for POST /v1/storefronts/{storefront_id_or_slug}/orders
 * Response body for POST /v1/storefronts/{storefront_id_or_slug}/orders/{id}
 * @see PurchaseOrderSchema
 */
export interface IPurchaseOrder {
  id: string;
  payment_term: IPaymentTerm;
  payment_mode: IPaymentMode;
  delivery_term: IDeliveryTerm;
  billing_address: IAddress;
  shipping_address: IAddress;
  required_eta: string; // date-time string, e.g. "2020-08-01T17:32:28Z"
  items: IOrderItem[];
  seller_documents: TransactionDocument[];
  buyer_documents: TransactionDocument[];
  modified_at: string; // date
  modified_by: string;
  created_at: string; // date
  created_by: string;
  fees: Fee[];
  total_fees: string;
  status: OrderStatus;
  number: string;
  seller_name: string;
  invoice: InvoiceSchema;
  buyer_id: string;
  seller_id: string;
  buyer_name: string;
  buyer_role: "buyer" | "distributor";
  documents: OrderDocument[];
  quote_request_id: string;
  alternative_po_number: string | null;
  seller_address: IAddress;
  shipment_advices?: IShipmentAdvice[];
  is_from_quote: boolean;
  is_tax_exempt: boolean;
  currency: CurrencyCode;
  lot_numbers: LotNumber[];
  payments?: Payment;
  tax_exempt_document?: ProductDocument;
  sold_by?: User;
  created_by_company_name?: string;
}
export type PurchaseOrderPaginatedOutput = WithPagination<{
  data: IPurchaseOrder[];
}>;

/**
 * Request body for POST /v1/storefronts/{storefront_id_or_slug}/orders/carts
 * @see PurchaseOrderReorderCartArgsSchema
 *
 * Request body for POST /v1/storefronts/{storefront_id_or_slug}/orders/carts/{id}/items
 * @see PurchaseOrderReorderCartItemAddArgsSchema
 *
 * (The two schemas are the same.)
 */
export interface PurchaseOrderReorderCartArgs {
  order_id: string;
  item_id: string;
  no_of_units: number; // decimal
  price_per_unit: number; // decimal
  currency?: CurrencyCode;
  delivery_term_id?: string;
  payment_term_id?: string;
  payment_mode_id?: string;
}

/**
 * Request body for POST /v1/storefronts/{storefront_id_or_slug}/orders/carts/{id}
 * @see PurchaseOrderReorderCreationArgsSchema
 */
export interface PurchaseOrderReorderCreationArgs {
  delivery_term_id: string;
  payment_term_id: string;
  payment_mode_id: string;
  required_eta: string;
}

export type LastPurchase = {
  number_of_units: string;
  price_per_unit: string;
  currency: CurrencyCode;
  sku: ProductSKU;
};

// export function isIOrderItem(item: Object): item is IOrderItem {
//   return item.hasOwnProperty("id") && item.has;
// }

/**
 * This type is used for order items, and also for reorder cart items,
 * since they are the same type as order items.
 *
 * Note: product is nullable in some cases with orders created on V2, not
 * updating the type yet because this is probably a bug.
 *
 * @see PurchaseOrderDetailSchema
 */
export interface IOrderItem {
  id: string;
  total_quantity: string;
  number_of_units: string;
  price_per_unit: string | null;
  currency: CurrencyCode;
  sku: ProductSKU | null;
  product_id: string;
  product_applications: ProductApplication[];
  custom_application?: string;
  documents: ProductDocument[];
  last_purchase: LastPurchase;
  lot_numbers: LotNumber[];
  product: {
    name: string;
    identifiers: Identifiers | null;
  };
  contract_id: string | null;
  total_value: string | null;
}

export interface INotification {
  message: string;
  type: "success" | "info" | "error" | "warning";
  error?: unknown;
}
export type LayoutComponentProps = {
  children: React.ReactNode;
  routes: (RouteType | ExternalRouteType)[];
  omitUserArea?: boolean;
  omitHeader?: boolean;
  fullWidth?: boolean;
  mxWidth?: string;
};

export type INavLayout = "admin" | "portal" | "public";
export interface INavLinkDetails {
  name: string;
  icon?: ({ fill, width, height }: IIconProps) => JSX.Element;
}

export type NavType = "internal" | "external";

export type RouteType = {
  component: React.FunctionComponent;
  path: string;
  browserTitle?: string;
  exact: boolean;
  permission?: Permission | Permission[];
  layout: FC<LayoutComponentProps>;
  children?: ReactNode;
  navType?: NavType;
  metaName?: string;
  // Some routes may be used with more than one layout (e.g. portal and public),
  // and may have different nav link details for each layout.
  nav?: Partial<Record<INavLayout, INavLinkDetails>>;
  parentNav?: string;
  internalName?: string;
  layoutProps?: {
    omitUserArea?: boolean;
    omitHeader?: boolean;
    fullWidth?: boolean;
    mxWidth?: string;
  };
};

export type ExternalRouteType = {
  path: string;
  nav: Partial<Record<INavLayout, INavLinkDetails>>;
  navType: NavType;
  metaName?: string;
  parentNav?: string;
};

export type IRoutePathKey = "accountPath" | "storePath" | "adminPath";

export type IRoutePaths = Record<IRoutePathKey, string>;

export interface ITag {
  id: string;
  image_url: string | null;
  description?: string;
  title?: string;
  link?: string;
}

type TransactionEventBase = {
  created_at: string;
  quote_message_id: string | null;
  purchase_order_id: string;
  id: string;
  creator_id: string;
  creator: User;
  quote_request_id: string | null;
  order_message_id: string | null;
  sample_request_id: string | null;
  event_type:
    | "status_update"
    | "buyer_edit"
    | "seller_edit"
    | "document_upload"
    | "quote_message"
    | "order_message"
    | "sample_message"
    | "shipment_advice"
    | "document_delete"
    | "purchase_order_number"
    | "payment_link_created"
    | "shipment_advice_changed"
    | "shipment_advice_deleted"
    | "checkout_session_paid"
    | "transaction_split";
  request_json: IMessageRequestJson | null; // This is the request JSON from any quote or order
  // action take by a buyer or seller.
  payment_mode: IPaymentMode | null;
  payment_term: IPaymentTerm | null;
  delivery_term: IDeliveryTerm | null;
  shipping_address: IAddress | null;
  product: Product | null;
  product_sku: ProductSKU | null;
  order_document: OrderDocument;
};

interface PurchaseOrderAction {
  purchase_order_action:
    | "accept"
    | "invoice"
    | "complete"
    | "decline"
    | "cancel"
    | "progress"
    | "ship_order"
    | null;
}

interface QuoteRequestAction {
  quote_request_action:
    | "request"
    | "respond"
    | "accept"
    | "cancel"
    | "decline"
    | null;
}

// First, merge PurchaseOrderAction and QuoteRequestAction exclusively
type POorQuoteExclusive = MergeExclusive<
  PurchaseOrderAction,
  QuoteRequestAction
>;

// Then, merge the result with SampleRequestAction exclusively
export type TransactionEvent = MergeExclusive<
  POorQuoteExclusive,
  SampleRequestAction
> &
  TransactionEventBase;

export type PaginatedTransactionEvents = WithPagination<{
  data: TransactionEvent[];
}>;

export type OrderMessageArgs = {
  message: string;
  message_type:
    | "Documents"
    | "Other"
    | "Packaging"
    | "Price"
    | "Product"
    | "Shipment";
};

/**
 * Success response (201) for POST to:
 * /v1/storefronts/{storefront_id_or_slug}/tenants/{tenant_id_or_slug}/products/{product_id}/images
 * @see ProductImageSchema
 */
export interface IProductImage {
  id: string;
  name: string;
  cdn_url: string;
  size: number;
}

/**
 * Request body for POST to:
 * /v1/{tenant_id_or_slug}/invite
 * @see TenantInviteArgsSchema
 *
 * response body for GET:
 * /v1/storefronts/{storefront_id_or_slug}/tenants/{tenant_id}/invite/{invite_id}
 */
export interface ITenantInviteArgs {
  company_name: string;
  contact_firstname?: string;
  contact_lastname?: string;
  contact_email: string;
  lead_id?: string;
  id?: string;
}

/**
 * @see TenantInviteSchema
 */
export interface ITenantInvite {
  company_name: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_email: string;
  id: string;
  lead: ILeadSchema | null;
  status: string;
}

/**
 * Response type for:
 * GET `/v1/storefronts/{storefront_id_or_slug}/tenants/{tenant_id}/invite
 * @see TenantInvitePaginatedOutputSchema
 */
export interface ITenantInvitePaginatedOutput {
  data: ITenantInvite[];
  pagination: IPagination;
}

/**
 * Request body for POST to:
 * /v1/storefronts/{storefront_id_or_slug}/orders/{order_id}/generated-invoice
 *
 * @see GeneratedInvoiceArgsSchema
 */
export interface IGeneratedInvoiceArgs {
  invoice_number: string;
  invoice_date: string;
  due_date: string;
  ship_via?: string;
  tracking_number?: string;
  additional_charges?: IChargeDiscountArgs[];
  notes?: string;
  is_upload: boolean;
}

/**
 * @see InvoiceSchema
 */
export type InvoiceSchema = {
  id: UUID;
  purchase_order_id: UUID;
  document: OrderDocument;
  invoice_number: string | null;
  invoice_date: string | null;
  due_date: string | null;
  ship_via: string | null;
  tracking_number: string | null;
  additional_charges: Fee[];
  notes: string | null;
};

/**
 * @see ChargeDiscountArgsSchema
 */
interface IChargeDiscountArgs {
  name: string;
  amount: string;
  currency: CurrencyCode;
}

/**
 * @see UserSchema
 */
interface UserSchema {
  id: number;
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  role: string;
  referral: string;
  phone_number: string;
  created_at: string;
  modified_at: string;
}

/**
 * Response body for POST:
 * /v1/storefronts/{storefront_id_or_slug}/invite/{invite_id}
 * @see PasswordResetTokenSchema
 */
export interface PasswordResetTokenSchema {
  id: string;
  user: UserSchema;
  expire_at: string;
}

/**
 * Represents a price tier for a given product + SKU + shipping_address + buyer
 * + seller + etc.
 *
 * `minimum_sku_quantity` refers to a minimum number of SKU units.
 *
 * `minimum_uom_quantity` refers to a minimum number of "unit of measure"
 * quantity, which is the value calculated by multiplying the number of SKU
 * units and the quantity of each SKU.
 *
 * For example, if the SKU is "Boxes (5 LBs)" and you have 5
 * `minimum_sku_quantity` then you have 25 LBs of `minimum_uom_quantity` (5 x 5).
 *
 * @see PriceTierSchema
 */
export interface IPriceTier {
  id: string; // UUID
  seller_id: string; // UUID
  buyer_id: string; // UUID
  destination: IAddress;
  product_sku: ProductSKU;
  delivery_term: IDeliveryTerm;
  payment_term: IPaymentTerm;
  payment_mode: IPaymentMode;
  minimum_uom_quantity: string; // decimal, e.g. 1234.56
  minimum_sku_quantity: string; // decimal, e.g. 1234.56
  price_per_uom: string; // decimal, e.g. 1234.56
  currency: CurrencyCode;
  valid_from_date: string; // datetime, e.g. '2021-08-01T12:34:56
  valid_to_date: string; // satetime, e.g. '2021-08-01T12:34:56
}

/**
 * Response body for:
 * GET /v1/price-tiers
 * @see PriceTierPaginatedOutputSchema
 */
export type IPriceTierPaginatedOutput = WithPagination<{
  data: IPriceTier[];
}>;

/**
 * Request body for:
 * POST /v1/price-tiers
 * @see PriceTierCreateSchema
 *
 * If you include quote_request_detail_id or purchase_order_detail_id then
 * there's no reason to include destination_id, product_sku_id,
 * delivery_term_id, payment_term_id, and payment_mode_id since they would
 * have no effect. In that case those values are ignored and are taken from the
 * quote request or purchase order.
 */
export interface IPriceTierCreate {
  price_tiers: {
    seller_id: string; // UUID
    buyer_id: string; // UUID
    price_per_uom: string; // decimal, e.g. 1234.56
    minimum_uom_quantity?: string; // decimal, e.g. 1234.56
    minimum_sku_quantity?: string; // decimal, e.g. 1234.56
    currency?: CurrencyCode;
    valid_from_date?: string; // datetime, e.g. '2021-08-01T12:34:56
    valid_to_date?: string; // satetime, e.g. '2021-08-01T12:34:56
    quote_request_detail_id?: string; // UUID
    purchase_order_detail_id?: string; // UUID
    //
    destination_id?: string; // UUID
    product_sku_id?: string; // UUID
    delivery_term_id?: string; // UUID
    payment_term_id?: string; // UUID
  }[];
}

/**
 * Request body for:
 * PATCH /v1/storefronts/{storefront_id}/price-tiers/{price_tier_id}
 * @see PriceTierPatchSchema
 */
export interface IPriceTierPatch {
  delivery_term_id?: string; // UUID
  payment_term_id?: string; // UUID
  payment_mode_id?: string; // UUID
  valid_from_date?: string; // datetime, e.g. '2021-08-01T12:34:56
  valid_to_date?: string; // satetime, e.g. '2021-08-01T12:34:56
  minimum_uom_quantity?: string; // decimal, e.g. 1234.56
  minimum_sku_quantity?: string; // decimal, e.g. 1234.56
  price_per_uom?: number; // decimal, e.g. 1234.56
  currency?: CurrencyCode;
  sku_id?: string;
}

/**
 * Response body for GET /v1/storefronts/{storefront_id_or_slug}/quotes/{quote_id}/items/{item_id}/price-tiers
 * Response body for GET /v1/storefronts/{storefront_id_or_slug}/orders/{order_id}/items/{item_id}/price-tiers
 * @see QuoteRequestPriceTierSchema
 */
export interface IQuoteRequestPriceTier {
  price_per_uom: string;
  price_tiers: IPriceTier[];
  currency: CurrencyCode;
}

/**
 * Response body for GET /v1/price-tiers/grouped
 *
 * @see GroupedPriceTierSchema
 */
export interface IGroupedPriceTier {
  seller_id: string;
  buyer_id: string;
  product: {
    id: string;
    name: string;
  };
  destination: IAddress;
  delivery_term: IDeliveryTerm;
  payment_term: IPaymentTerm;
  payment_mode: IPaymentMode;
  currency: CurrencyCode;
  valid_from_date: string; // datetime
  valid_to_date: string; // datetime
  terms: IGroupedPriceTierSingleTier[];
}

export interface IGroupedPriceTierSingleTier {
  product_sku: ProductSKU;
  price_per_uom: string; // decimal
  minimum_uom_quantity: string; // decimal
  minimum_sku_quantity: string; // decimal
  tier_id: string;
}

/**
 * Response body for GET /v1/last-purchase
 */
export interface LastPurchaseResponse {
  currency: CurrencyCode;
  number_of_units: string;
  price_per_unit: string;
  sku: ProductSKU;
}

export interface AccountSubscriber {
  id: UUID;
  firstname: string;
  lastname: string;
  email_address: string;
}
/**
 * Response body of GET, POST, and PATCH to:
 * /v1/storefronts/{storefront_id_or_slug}/tenants/{seller_id_or_slug}/customers/{buyer_id_or_slug}/settings
 *
 * @see TenantCustomerSettingsSchema
 */
export interface ITenantCustomerSettings {
  account_manager?: User;
  customer_service_rep?: User;
  default_delivery_term?: IDeliveryTerm;
  default_payment_mode?: IPaymentMode;
  default_payment_term?: IPaymentTerm;
  preferred_currency?: CurrencyCode;
  is_tax_exempt?: boolean;
  tax_exempt_document?: ProductDocument;
  subscribers?: AccountSubscriber[];
  notification_preferences?: {
    quote_notifications?: boolean;
    order_notifications?: boolean;
    sample_request_notifications?: boolean;
  };
}

/**
 * Request body for POST and PATCH to:
 * /v1/storefronts/{storefront_id_or_slug}/tenants/{seller_id_or_slug}/customers/{buyer_id_or_slug}/settings
 *
 * @see TenantCustomerSettingsCreationSchema
 */
export interface ITenantCustomerSettingsCreation {
  account_manager_id?: string; // UUID
  customer_service_rep_id?: string; // UUID
  default_delivery_term_id?: string; // UUID
  default_payment_mode_id?: string; // UUID
  default_payment_term_id?: string; // UUID
  preferred_currency?: CurrencyCode;
  is_tax_exempt?: boolean;
}

/**
 * @see SampleRequestCreationArgsSchema
 */
export type SampleRequestCreationArgs = {
  product_id: string;
  purpose: string;
  total_quantity: string;
  total_quantity_packaging_unit_id: string;
  sample_quantity?: string;
  sample_quantity_packaging_unit_id?: string;
  applications?: string[];
  custom_application?: string;
  sku_id: string;
  no_of_units: string;
};

export interface ISampleRequestItemPatchArgs {
  item_id: string;
  sku_id: string;
  no_of_units: string;
  total_quantity: string;
  packaging_unit_id?: string;
  custom_packaging_type_id?: string;
  custom_packaging_quantity?: string;
  requested_sku_id?: string;
  requested_no_of_units?: string;
  requested_total_quantity?: string;
}

/**
 * named as such so as not to conflict with SampleRequestItem component
 */
export type SampleRequestIndividualItem = {
  packaging_unit: Pick<IPackagingUnit, "name" | "id"> | null;
  product: {
    name: string;
    identifiers?: Identifiers;
  };
  purpose: string;
  product_id: string;
  total_quantity: string;
  sample_quantity_packaging_unit: IPackagingUnit | null;
  total_quantity_packaging_unit: IPackagingUnit | null;
  sample_quantity: string;
  id: string;
  applications?: ProductApplication[];
  custom_application?: string;
};

export type SampleRequestStageOne = {
  buyer_first_name: null;
  buyer_address: null;
  buyer_id: null;
  status: "new";
  buyer_last_name: null;
  buyer_email: null;
  buyer_company_name: null;
  modified_at: string;
  seller_id: string;
  buyer_phone: null;
  id: string;
  items: SampleRequestIndividualItem[];
};

export type SampleRequestStatusUnion =
  | "requested"
  | "accepted"
  | "new"
  | "forwarded"
  | "converted"
  | "in_progress"
  | "shipped"
  | "pending"
  | "completed"
  | "Added as User"
  | "rejected"
  | "pending_activation";

/**
 * Request body to /v1/storefronts/{storefront_id_or_slug}/sample-requests/{id}
 * This is used once the sample request is already in @See SampleRequestStageOne
 */
export type PatchSampleRequest = Omit<IAddressInfo, "postal_code"> & {
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_email: string;
  buyer_phone: string;
  buyer_company_name: string;
  zip_code: string;
  country: string;
  county?: string;
  state: string;
  requested_documents: string[];
  decision_timeline: string;
  is_decision_maker: boolean;
  billing_address?: IAddressInfo & { county?: string };
};

export type SampleRequestStageTwo = {
  buyer_first_name: string;
  buyer_company_name: string;
  buyer_address: IAddress;
  buyer_email: string;
  buyer_id: null;
  buyer_phone: string;
  status: SampleRequestStatusUnion;
  assignee: LeadAssignee | null;
  assignment_date: string | null;
  items: SampleRequestIndividualItem[];
  buyer_last_name: string;
  modified_at: string;
  id: UUID;
  number: string; // human readable id
  seller_id: string;
  created_at: string; // Date
  is_decision_maker: boolean;
  decision_timeline: string;
  requested_documents: string[];
  is_existing_customer: boolean;
  company_name: string;
  customer_id: string;
  parent_customer_id: string;
  shipping_address?: IAddress;
  billing_address?: IAddress;
  required_eta?: string;
  internal_notes: LeadNote[];
  rejection_note?: RejectionNote;
  region?: string;
};

/**
 * @see SampleRequestSchema where this enum is used.
 */
export type SampleRequestStatus =
  | "new"
  | "requested"
  | "accepted"
  | "rejected"
  | "cancelled"
  | "completed"
  | "in_progress"
  | "shipped"
  | "pending"
  | "no_request"
  | "pending_activation";

export type SampleStatusLabel =
  | "New"
  | "Accepted"
  | "In Progress"
  | "Shipped"
  | "Requested"
  | "Cancelled"
  | "Rejected"
  | "No Request"
  | "Pending Activation"
  | "Completed";

interface SampleRequestAction {
  sample_request_action:
    | "accept"
    | "complete"
    | "decline"
    | "cancel"
    | "reject"
    | "progress"
    | "ship_sample"
    | null;
}

/**
 * Sample status action to be sent to the BE to update the Sample status
 */
export type SampleStatusAction = Exclude<
  SampleRequestAction["sample_request_action"],
  null
>;

/**
 * Response body for
 * GET /v1/storefronts/{storefront_id}/sample-requests/{sample_request_id}
 *
 * @see SampleRequestDetailSchema
 */
export interface SampleRequestDetail {
  id: string;
  total_quantity: string;
  sample_quantity: string;
  purpose: string;
  sku: ProductSKU;
  // not sure if these units are part of PIM
  total_quantity_packaging_unit: IPackagingUnit | null;
  sample_quantity_packaging_unit: IPackagingUnit | null;
  product_id: string;
  applications: ProductApplication[];
  custom_application?: string;
  documents: ProductDocument[];
  product: {
    name: string;
    identifiers?: Identifiers;
    // The SDS and TDS document URLs were put here in haste to meet a deadline on
    // Jan 6, 2022. To match what we do with Orders, there should be an array
    // like `documents: ProductDocument[]` on the SampleRequestDetail object.
    safety_datasheet?: string; // SDS (ProductDocument.signed_url)
    technical_datasheet?: string; // TDS (ProductDocument.signed_url)
  };
  no_of_units: string;
}

/**
 * @see OrderDocumentSchema
 */
type SampleRequestDocument = OrderDocument;

/**
 * @see SampleRequestSchema
 */
export interface SampleRequest extends Omit<IAddressInfo, "postal_code"> {
  id: string;
  modified_at: string;
  created_at: string;
  created_by: string;
  modified_by: string;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_company_name: string;
  county: string;
  zip_code: string;
  items: SampleRequestDetail[];
  seller_documents: TransactionDocument[];
  buyer_documents: TransactionDocument[];
  seller_id: string;
  buyer_id: string;
  status: SampleRequestStatus;
  shipping_address?: IAddress;
  seller_address?: IAddress;
  buyer_address?: IAddress;
  buyer_role: "buyer" | "distributor" | undefined;
  requested_by?: IAddress;
  number: string;
  decision_timeline: string;
  is_decision_maker: boolean;
  requested_documents: string[];
  shipment_advices?: IShipmentAdvice[];
  documents: SampleRequestDocument[];
  sold_by: User;
  company_name: string;
  created_by_company_name: string;
}

/**
 * Response body for:
 * GET /v1/storefronts/{storefront_id}/sample-requests
 *
 * @see SampleRequestPaginatedOutputSchema
 */
export type SampleRequestPaginatedOutput = WithPagination<{
  data: SampleRequest[];
}>;

/**
 * Request body for:
 * POST /v1/storefronts/{storefront_id}/sample-requests/{sample_request_id}
 *
 * Used to submit the logged in buyer sample request cart.
 *
 * @see SampleRequestSubmitLoginArgsSchema
 */
export interface SampleRequestSubmitLoginArgs {
  is_decision_maker: boolean;
  decision_timeline: string;
  shipping_address_id: string;
  requested_documents?: string[];
}

export interface ICountry {
  alpha_2: Alpha2;
  country_code: string;
  name: string;
  official_name: string;
}
export interface IState {
  code: string;
  country_code: string;
  display_name: string;
  name: string;
  parent_code: string;
  type: string;
}

export type ICountriesAPIResponse = ICountry[];
export type IStatesAPIResponse = IState[];

/**
 * An array of these records are returned from:
 * GET /v1/storefronts/{id}/tenant/defaults/delivery-terms/{id}
 * GET /v1/storefronts/{id}/tenant/defaults/payment-terms/{id}
 * GET /v1/storefronts/{id}/tenant/defaults/payment-modes/{id}
 */
export interface ITenantNameAndId {
  id: string;
  name: string;
}

/**
 * @see MyProductSchema
 */
export type MyProductsProduct = {
  currency: CurrencyCode;
  modified_at: string | null; // Date
  number_of_units: number;
  price_per_unit: number | null;
  product: {
    name: string;
    id: UUID;
    identifiers: Identifiers;
    status: ProductStatusType;
  };
  product_id: UUID;
  transacted_product_id: UUID | null;
  cas_number: string;
  chemical_name: string;
  ec_number: string;
  inchi_key: string;
  inci_name: string | null;
  molecular_formula: string | null;
  synonyms: string | null;
  product_price_tiers: IPriceTier[];
  total_quantity: number;
  ytd_quantity: number | null;
  order: IPurchaseOrder | null;
};

/**
 * response body for `/v1/storefronts/{id}/my-products.
 * This is not a typo in the schema name, the endpoint is really plural and the
 * schema singular.
 * @see MyProductPaginatedOutputSchema
 */
export type MyProductsPaginatedOutput = WithPagination<{
  data: MyProductsProduct[];
}>;

/**
 * Supposedly only one can exist at once but I have never personally seen the
 * `order_cart` exist.
 * @see UnifiedCartGetSchema
 * @see PurchaseOrderCartSchema this is an alias for the stock
 * purchase order schema.
 */
export type StorefrontUnifiedCart = {
  quote?: IQuoteRequest;
  order_cart?: IPurchaseOrder;
};
export interface IEmailNotificationTemplate {
  subject: string | undefined;
  header: string | undefined;
  content: string | undefined;
  footer: string | undefined;
  recipients: string | undefined;
  template: string;
}

export interface IEmailTemplateEditorData {
  label: string;
  language: SupportedLanguage;
  key: EmailTemplateKey;
  templateData?: IStorefrontEmailTemplate;
}

export type SupportedLanguage = string;

export type Language = {
  alpha_2: SupportedLanguage;
  alpha_3: string;
  name: string;
  is_deletable?: boolean;
};

export type IStorefrontEmailTemplateBodyPreview = string;

/**
 * Response body for GET to:
 * /v1/storefronts/{id}/email-templates/{template_id}
 * /v1/email-templates/{template_id}
 */
export interface IStorefrontEmailTemplate {
  id: string;
  template_key: EmailTemplateKey;
  language: SupportedLanguage;
  template_body: string;
  template_subject: string;
  template_file: string;
  // The first string is `{{variable_name}}` and the second is the description.
  template_variables: [string, string][];
  email_body: string | null;
  customer_email_text: string;
  customer_email_thanks: string;
  custom_header: string;
  custom_footer: string;
  subject?: string;
  recipients: string;
}

/**
 * Request body for POST to:
 * /v1/storefronts/{storefront_id_or_slug}/email-templates
 *
 * @see StorefrontCustomTemplateCreateSchema
 */
export interface IStorefrontCustomTemplateCreate {
  template_key: string;
  template_body: string;
  template_subject: string;
  language: SupportedLanguage;
  custom_header?: string;
  custom_footer?: string;
  email_body?: string | null;
}

/**
 * Request body for PATCH to:
 * /v1/storefronts/{storefront_id_or_slug}/email-templates/{template_id_or_slug}
 *
 * @see StorefrontCustomTemplatePatchSchema
 */
export interface IStorefrontCustomTemplatePatch {
  template_body?: string; // Usually you don't want to touch this in a PATCH.
  template_subject?: string;
  language?: SupportedLanguage;
  custom_header?: string;
  custom_footer?: string;
  email_body?: string | null;
}

export type EmailTemplateKey =
  | "tenant_activation"
  | "user_activation"
  | "distributor_activation"
  | "forward_lead"
  | "to_sales_team_from_agilis_site_agilis_site_registration"
  | "to_sales_team_from_agilis_site_agilis_site_demo_request"
  | "to_sales_team_from_agilis_site_agilis_contact_request"
  | "notify_seller_quote_request"
  | "notify_seller_ul_prospector_connector"
  | "notify_seller_contact_form"
  | "notify_seller_registration"
  | "notify_customer_agilis_site_registration"
  | "notify_customer_registration"
  | "notify_customer_agilis_contact_request"
  | "notify_customer_contact_form"
  | "notify_customer_agilis_site_demo_request"
  | "notify_customer_quote_request"
  | "notify_customer_ul_prospector_connector"
  | "notify_customer_lead_rejected_agilis_site_registration"
  | "notify_customer_lead_rejected_registration"
  | "notify_customer_lead_rejected_agilis_contact_request"
  | "notify_customer_lead_rejected_contact_form"
  | "notify_customer_lead_rejected_agilis_site_demo_request"
  | "notify_customer_lead_rejected_quote_request"
  | "notify_customer_lead_rejected_ul_prospector_connector"
  | "transaction_quote_no_request"
  | "transaction_quote_new"
  | "transaction_quote_requested"
  | "transaction_quote_responded"
  | "transaction_quote_accepted"
  | "transaction_quote_cancelled"
  | "transaction_quote_declined"
  | "transaction_order_no_order"
  | "transaction_order_new"
  | "transaction_order_accepted"
  | "transaction_order_delivered"
  | "transaction_order_invoiced"
  | "transaction_order_closed"
  | "transaction_order_declined"
  | "transaction_order_cancelled"
  | "forgot_username"
  | "forgot_password";

// Use this type to pass `methodsOfUseForm` (the return value of `useForm`) as
// a prop.
export type MethodsOfUseForm = Pick<
  ReturnType<typeof useForm>,
  keyof UseFormMethods
>;

export type CreatedAcceptedOrderItem = {
  product_id: UUID;
  sku_id: UUID;
  sku: TenantSkuCreationArgs | UUID;
  price_per_uom: number; // This may not be consistent, should confirm.
  no_of_units: number; // Can this be decimal?,
  applications: string; // only one is now possible.
};

/**
 * POST body for /v1/storefronts/{id}/transactions
 * TODO: update with schema reference when it exists
 */
export type CreateAcceptedOrder = {
  transaction_state: "order_accepted"; // in the future will be enum.
  data: {
    buyer_id: UUID;
    tenant_id: UUID;
    order_placed_via: "Phone" | "Email" | "Text" | "Fax";
    shipping_address_id: UUID;
    delivery_term_id: UUID; // Potentially change to shipping_term_id
    payment_term_id: UUID;
    requested_eta: Date;
    payment_mode_id: UUID; // technically a payment method
    items: CreatedAcceptedOrderItem[];
  };
};

export type SelectionWizardFilters = {
  filter_values: string[];
  filter_key: string;
  filter_question: string;
};

/**
 * response body for /v1/storefronts/{id}/selections
 */
export type SelectionWizardResponse = {
  filter_query_parameters: string;
  show_submit: boolean;
  is_final: boolean;
  filters: SelectionWizardFilters[];
};

// #/components/schemas/StorefrontPurchaseOrderReportFieldSchema
export type StorefrontPurchaseOrderReportField = {
  field_name: string;
  display_name: string;
  field_type: string | number | Array<any>;
};

export type DownloadLinkOptions = {
  name: string;
  type: string; // example: "text/csv"
  data: string;
};

/**
 * POST /v1/storefronts/{id}/support
 */
export interface ISupportRequest {
  description: string;
  name: string;
  email: string;
  phone_number: string;
  company_name: string;
}

/**
 * backend schema: StorefrontFilterSettingsSchema
 */
export interface StorefrontFilterSetting {
  filter_type: FilterType;
  is_active: boolean;
  is_visible: boolean;
  is_searchable?: boolean | undefined;
  has_product: boolean;
  filter_labels: FilterLabel[];
}
export interface NotificationObject {
  ids: UUID[];
  total: number;
}

export interface InAppNotificationsResponse {
  leads: {
    contact_us: NotificationObject;
    sample_requests: NotificationObject;
    registrations: NotificationObject;
    quote_requests: NotificationObject;
    total: number;
  };
  samples: NotificationObject;
  quotes: NotificationObject;
  orders: NotificationObject;
}

export type DataMutate<T> = (
  data?: T | Promise<T> | mutateCallback<T>,
  shouldRevalidate?: boolean
) => Promise<T | undefined>;

/**
 * GET & PATCH
 * controls whether product resources such as links and documents are visible/downloadable
 * outide of login at the storefront level. Takes precedence over the product
 * level settings for the same resources.
 */
export type StorefrontProductResourcesVisibility = {
  is_sds_visible: boolean;
  is_tds_visible: boolean;
  is_misc_visible: boolean;
  is_links_visible: boolean;
};

export type FontType = "large" | "medium" | "regular" | "small";

export type Payment = {
  /**
   * (url of most recent payment_link),
   */
  payment_link_url: URL;
  /**
   * amount (in USD) of charge in payment link
   */
  payment_link_amount: string;
  /**
   * amount (in USD) of expected stripe fee,
   */
  estimated_stripe_fee: string;
  /**
   * amount (in USD) of expected agilis fee
   */
  estimated_agilis_fee: string;
  /**
   * net amount (in USD),
   */
  estimated_net: string;
  payment_status:
    | "payment_link_created"
    | "charge_failed"
    | "charge_succeeded"
    | "payment_complete";
  payment_amount?: string; // amount (in USD) of successful payment,
  stripe_fee?: string; // amount (in USD) of collected stripe fee
  agilis_fee?: string; // amount (in USD) of collected agilis fee
  payment_net?: string; // amount (in USD) of net payment to seller,
  receipt_url?: string; // url from stripe with buyer receipt
};

/**
 * @see StripePaymentLinkSchema
 */
export type StripePaymentLinkSchema = {
  checkout_sessions: unknown;
  created_at: string;
  modified_at: string;
  application_fee_amount: UUID;
  id: UUID;
  active: boolean;
  modified_by: string;
  payment_link_id: string;
  url: string;
  created_by: string;
  order_id: UUID;
};

export type PaymentFeePercentages = {
  agilis_application_fee_base: string;

  agilis_application_fee_percent: string;

  stripe_fee_base: string;

  stripe_fee_percent: string;
};

export type DecisionTimeline =
  | "< 3 months"
  | "3 - 6 months"
  | "6 - 9 months"
  | "1+ years"
  | "Not sure";

export type TransactionPlacedVia = "Email" | "Fax" | "Phone" | "Text";

export type RequestableDocuments = "SDS" | "TDS" | "COA";

/**
 * Potential post body for /v1/transactions
 */
export type CreateSampleRequest = {
  transaction_state: "sample_accepted";
  data: {
    buyer_id: UUID;
    tenant_id: UUID;
    order_placed_via: TransactionPlacedVia;
    shipping_address_id: UUID;
    is_decision_maker: boolean;
    decision_timeline: DecisionTimeline;
    requested_documents?: RequestableDocuments[];
    items: {
      product_id: UUID;
      purpose: string;
      no_of_units: string;
      total_quantity: string;
      total_quantity_packaging_unit_id?: UUID;
      sku: {
        id: UUID;
      };
    }[];
    buyer_user_id: string | null;
  };
};

export type TaxExemptionDocument = {
  kind: "customer_tax_exemption";
  name: string;
  size: number;
  signed_url: string;
  id: string;
};

/**
 * @see DashboardSummarySchema
 */
export type DashboardSummary = {
  total_active_products: number;
  total_active_assets: number;
  total_active_customers: number;
  total_active_users: number;
  average_active_completion_score: number;
  average_published_completion_score: number;
};

export type DashboardUpdate = {
  type:
    | "product"
    | "list"
    | "assets"
    | "attribute"
    | "collection"
    | "group"
    | "template";
  last_modified_date: string;
  type_id: string;
  modified_by: {
    name: string;
    role: string;
  };
};

export type ProductDashboardUpdateData = {
  update_type: "product";
  product_id: string;
  name: string;
  updated_properties: string[];
  status: ProductStatusType;
};

export type ListDashboardUpdateData = {
  update_type: "list";
  name: string;
  status: "archived" | "created" | "updated";
  display_name: string;
  values_added?: string[];
  values_removed?: string[];
};

export type TemplateDashboardUpdateData = {
  updated_type: "template";
  name: string;
  status: "created" | "updated";
  updated: string[];
};

export type AssetDashboardUpdateData = {
  updated_type: "asset";
  name: string;
  file_name: string;
  asset_type: string;
  asset_category: string;
  language: SupportedLanguage;
  status: "created" | "updated" | "deleted";
};

export type GroupDashboardUpdateData = {
  updated_type: "group";
  name: string;
  display_name: string;
  attributes_added?: string[];
  attributes_removed?: string[];
  status: "created" | "updated" | "archived";
};

export type CollectionDashboardUpdateData = {
  updated_type: "collection";
  name: string;
  display_name: string;
  attributes_added?: string[];
  attributes_removed?: string[];
  status: "created" | "updated" | "archived";
};

export type AttributeDashboardUpdateData = {
  updated_type: "attribute";
  name: string;
  display_name: string;
  description: string;
  type: AttributeDataType;
  status: "created" | "updated" | "archived";
};

export type DashboardProductUpdate = DashboardUpdate & {
  update_data: ProductDashboardUpdateData;
};

export type DashboardOtherUpdate = DashboardUpdate & {
  update_data:
    | ListDashboardUpdateData
    | TemplateDashboardUpdateData
    | AssetDashboardUpdateData
    | GroupDashboardUpdateData
    | CollectionDashboardUpdateData
    | AttributeDashboardUpdateData;
};

export type StorefrontSummarySchema = {
  id: UUID;
  header: string;
  slug: string;
};

export type ProductFiltersResponse = {
  [key: string]: string;
};

export type ProductSelectorCSVDocument = {
  name: string;
  size: number;
  signed_url: string;
  id: string;
};
export type StorefrontCustomizableFormSchema = {
  is_active: boolean;
  csv_filename: string;
  custom_fields: {
    name: string;
    is_required: boolean;
    choices: {
      choice_name: string;
    }[];
  }[];
  dependent_choices?: {
    choice_name: string;
    choices: {
      choice_name: string;
      choices?: {
        choice_name: string;
      }[];
    }[];
  }[];
};

export type TeamSummary = {
  team_id: UUID;
  team_name: string;
  status: "active" | "archived";
  number_of_users: number;
  number_of_products: number;
  tenant_id: UUID;
};

export type TeamListResponseSchema = WithPagination<{
  data: TeamSummary[];
}>;

export type AssignedUserSchema = {
  user_id: UUID;
  firstname: string;
  lastname: string;
  role: string | null; // Do we have a type for this?
  is_active: boolean;
};

export type TeamDetailsSchema = {
  team_id: UUID;
  team_name: string;
  users: AssignedUserSchema[];
  products: AssignedProductSchema[];
  status: string;
};
/**
 * @see TeamCreateSchema
 */
export type TeamCreateSchema = {
  team_name: string;
  user_ids: UUID[];
  product_ids: UUID[];
};

export type AssignedProductSchema = {
  product_id: UUID;
  product_name: string;
  status: ProductStatusType;
  product_number: string; // human readable ID
  product_schema: PIMProduct["product_schema"];
};

export type TeamCreateResponseSchema = TeamSummary;

export type UpdateTeamSchema = {
  team_name: string;
  user_ids_to_add: UUID[];
  user_ids_to_remove: UUID[];
  product_ids_to_add: UUID[];
  product_ids_to_remove: UUID[];
};

export type TranslationsSchema = {
  language: SupportedLanguage;
  display_name: string;
  description: string;
};

export type SampleRequestNextStatusSchema = {
  transitions: SampleRequestStatus[];
};

export type UserPaginatedOutputSchema = WithPagination<{ data: User[] }>;

export interface ISampleRequest extends Omit<IAddressInfo, "postal_code"> {
  id: string;
  modified_at: string;
  created_at: string;
  created_by: string;
  modified_by: string;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_company_name: string;
  county: string;
  zip_code: string;
  items: SampleRequestDetail[];
  seller_id: string;
  buyer_id: string;
  status: SampleRequestStatus;
  shipping_address?: IAddress;
  seller_address?: IAddress;
  number: string;
  decision_timeline: string;
  is_decision_maker: boolean;
  requested_documents: string[];
  shipment_advice?: IShipmentAdvice;
  documents: SampleRequestDocument[];
}

export type ProductUploadSchema = {
  id: string;
  upload_number: string;
  template_name: string;
  status: string;
  created_at: string;
  created_by?: string | null;
  upload_log_url?: string | null;
};

export type StorefrontEmailReminderKind =
  | "accept_price"
  | "accept_order"
  | "contact_us"
  | "quote_request"
  | "quote_request_lead"
  | "register"
  | "respond_sample"
  | "sample_request";

export type StorefrontEmailReminderNumberRange =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14;

export type StorefrontEmailReminderSchema = {
  first_reminder: StorefrontEmailReminderNumberRange | null;
  second_reminder: StorefrontEmailReminderNumberRange | null;
  reminder_type: StorefrontEmailReminderKind;
};

export type StorefrontEmailRemindersSchema = {
  reminder_data: StorefrontEmailReminderSchema[];
};

export type AccountManagerContactInfo = {
  contact_last_name: string;
  contact_first_name: string;
  contact_name: string;
  country: string;
  id: string;
  phone_number: string;
  email_address: string;
  crm_id?: string | null;
  is_active: boolean;
};

export type StorefrontChatbotConfiguration = {
  avatar: string;
  id: string;
  intro_message: string;
  inside_login_disclaimer: string;
  kind: string;
  name: string;
  is_customer_enabled: boolean;
};
