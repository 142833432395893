import { Controller } from "react-hook-form";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { Form, SubmitButtonContainer } from "../../../../../layout/FormLayout";
import type { FormSource } from "./CreatePimProduct.utils";
import { InputWrapper } from "./CreatePimProduct.utils";
import { H5 } from "../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { z as zod } from "zod";
import {
  zodRequiredString,
  zodSelectBoxDefault,
} from "../../../../../util/zod.util";
import {
  TEMPORARY_HIGH_LIMIT,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { useEffect, useState } from "react";
import type { OptionType, WithPagination } from "../../../../../types/types";
import useSWR from "swr";
import type {
  AttributeTemplateSummarySchema,
  PIMProductBase,
} from "../../../../../types/types.PIM";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { PrimaryButtonLarge } from "../../../../../components/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import { useRoutePath } from "../../../../../util/Routing";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";

export const CreateNewProductPage = ({
  formSource,
}: {
  formSource: FormSource;
}) => {
  const [productTemplatesOptions, setProductTemplatesOptions] = useState<
    OptionType<string>[]
  >([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const { notifySuccess, notifyError } = useNotifications();
  const history = useHistory();

  const PimNewProductSchema = zod.object({
    brand_name: zodRequiredString(t),
    product_template: zodSelectBoxDefault(t),
  });

  type FormInputs = zod.infer<typeof PimNewProductSchema>;

  const { handleSubmit, register, formState, errors, control, watch, reset } =
    useFormWrapper<FormInputs>({
      resolver: zodResolver(PimNewProductSchema),
    });

  const selectedTemplate: OptionType = watch("product_template");

  useSWR<
    WithPagination<{ data: AttributeTemplateSummarySchema[] }>,
    AxiosError
  >(
    `${endpoints.v2_storefronts_id_pim_templates_summary(
      tenant_id
    )}?limit=${TEMPORARY_HIGH_LIMIT}&order_by=asc`,
    {
      onSuccess: ({ data }) =>
        setProductTemplatesOptions(
          data.map(({ template_name, template_id }) => ({
            label: template_name,
            value: template_id,
          }))
        ),
    }
  );

  const onSubmit = (data: FormInputs) => {
    setLoading(true);
    const url = endpoints.v2_tenants_id_pim_products_summary(tenant_id);
    axios
      .post<PIMProductBase>(url, {
        name: data.brand_name.trim(),
        source: "template",
        template_id: selectedTemplate.value,
      })
      .then((result) => {
        notifySuccess(
          `${result.data.name} ${t("Product created successfully")}`
        );
        setLoading(false);
        history.push(
          `${adminPath}/pim/products/${
            result.data.product_number ?? result.data.id
          }?source=new`
        );
      })
      .catch((error: AxiosError) => {
        notifyError(
          error.response
            ? error.response.data.message
            : t(`There was an error creating the product, please try again`),
          { error }
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (formSource === "new") {
      reset();
    }
  }, [formSource, reset]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <H5 style={{ marginTop: "25px" }}>{t("Product Details")}</H5>
      <InputWrapper>
        <TextField
          name="brand_name"
          label={t("Product Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="product_template"
          placeholder={t("Template")}
          id="product_template"
          options={productTemplatesOptions}
          rules={{
            required: true,
          }}
          defaultValue={{ value: "", label: "" }}
          errors={errors}
          formState={formState}
        />
      </InputWrapper>
      <SubmitButtonContainer>
        <PrimaryButtonLarge loading={loading}>
          {t("Create Product")}
        </PrimaryButtonLarge>
      </SubmitButtonContainer>
    </Form>
  );
};
