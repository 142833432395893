import { useEffect, useState } from "react";
import { PrimaryButtonLarge } from "../../../../../components/Buttons/Buttons";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { endpoints } from "../../../../../endpoints";
import {
  Form,
  RadioButtonContainer,
  SubmitButtonContainer,
} from "../../../../../layout/FormLayout";
import type { OptionType } from "../../../../../types/types";
import type {
  PIMProduct,
  PIMProductBase,
} from "../../../../../types/types.PIM";
import type { FormSource } from "./CreatePimProduct.utils";
import { InputWrapper } from "./CreatePimProduct.utils";
import { useTranslation } from "react-i18next";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import { z as zod } from "zod";
import {
  zodOptionalSelectBoxDefault,
  zodRequiredString,
} from "../../../../../util/zod.util";
import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import axios from "axios";
import { useRoutePath } from "../../../../../util/Routing";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { useHistory } from "react-router-dom";
import { strings } from "../../../../../util/strings";

type IExistingProduct = "master_product" | "existing_product";

export const CreateFromExistingProductPage = ({
  formSource,
}: {
  formSource: FormSource;
}) => {
  const [activeExistingProduct, setActiveExistingProduct] =
    useState<IExistingProduct>("master_product");
  const [selectedItem, setSelectedItem] = useState<OptionType>();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const { notifySuccess, notifyError } = useNotifications();
  const history = useHistory();

  const PIMExistingProductSchema = zod.object({
    master_product_search: zodOptionalSelectBoxDefault,
    brand_name: zodRequiredString(t),
    tenant_product_search: zodOptionalSelectBoxDefault,
  });

  type FormInputs = zod.infer<typeof PIMExistingProductSchema>;

  const { handleSubmit, register, formState, errors, setValue, reset } =
    useFormWrapper<FormInputs>({
      resolver: zodResolver(
        PIMExistingProductSchema.superRefine(
          ({ master_product_search, tenant_product_search }, ctx) => {
            if (
              activeExistingProduct === "master_product" &&
              !master_product_search?.value
            ) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: strings(t).thisIsARequiredField,
                path: ["master_product_search"],
              });
            }
            if (
              activeExistingProduct === "existing_product" &&
              !tenant_product_search?.value
            ) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: strings(t).thisIsARequiredField,
                path: ["tenant_product_search"],
              });
            }
          }
        )
      ),
      defaultValues: {
        master_product_search: { value: "", label: "" },
        tenant_product_search: { value: "", label: "" },
      },
      mode: "onSubmit",
      reValidateMode: "onChange",
    });

  const handleSearchSelected = (data: OptionType<string>) => {
    setValue("brand_name", data?.label);
    if (activeExistingProduct === "master_product") {
      setValue("master_product_search", data);
    } else {
      setValue("tenant_product_search", data);
    }
    setSelectedItem(data);
  };

  const onSubmit = (data: FormInputs) => {
    setLoading(true);
    const url = endpoints.v2_tenants_id_pim_products_summary(tenant_id);

    axios
      .post<PIMProduct>(url, {
        product_id: selectedItem?.value,
        source: activeExistingProduct,
        name: data.brand_name.trim(),
      })
      .then((result) => {
        setLoading(false);
        notifySuccess(
          `${result.data.name} ${t("Product created successfully")}`
        );
        history.push(
          `${adminPath}/pim/products/${
            result.data.product_number ?? result.data.id
          }?source=new`
        );
      })
      .catch((error: AxiosError) => {
        notifyError(
          error.response
            ? error.response.data.message
            : t(`There was an error creating the product, please try again`),
          { error }
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    register("master_product_search");
    register("tenant_product_search");
  }, [register]);

  useEffect(() => {
    if (formSource === "existing") {
      setActiveExistingProduct("master_product");
      reset();
    }
  }, [formSource, reset]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <RadioButtonContainer>
          <RadioButton
            name={"existingSource"}
            value="master_product"
            checked={activeExistingProduct === "master_product"}
            optionTitle={t("Master Product")}
            handleChange={() => {
              setActiveExistingProduct("master_product");
              reset();
            }}
          />
          <RadioButton
            name={"existingSource"}
            value="existing_product"
            checked={activeExistingProduct === "existing_product"}
            optionTitle={t("Your Products")}
            handleChange={() => {
              setActiveExistingProduct("existing_product");
              reset();
            }}
          />
        </RadioButtonContainer>

        {activeExistingProduct === "master_product" && (
          <SearchSelectInfiniteScroll
            name={"master_product_search"}
            errors={errors}
            formState={formState}
            placeholder={t("Search master products")}
            baseUrl="/v1/products"
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              return params;
            })()}
            getOptions={(response: PIMProductBase[]) =>
              response.map((product) => ({
                label: product.name,
                value: product.id,
              }))
            }
            onChange={(data: any) => handleSearchSelected(data)}
            testid={"master-product-search"}
          />
        )}

        {activeExistingProduct === "existing_product" && (
          <SearchSelectInfiniteScroll
            name={"tenant_product_search"}
            errors={errors}
            formState={formState}
            placeholder={t("Search from your products")}
            baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
            params={(() => {
              const params = new URLSearchParams();
              params.append("order_by", "asc");
              params.append("show_inactive", "true");
              return params;
            })()}
            getOptions={(response: PIMProductBase[]) =>
              response.map((product) => ({
                value: product.id,
                label: product.name,
              }))
            }
            onChange={(data: any) => handleSearchSelected(data)}
            testid={"existing-product-search"}
          />
        )}

        <TextField
          name="brand_name"
          label={t("Product Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
      </InputWrapper>
      <SubmitButtonContainer>
        <PrimaryButtonLarge loading={loading}>
          {t("Create Product")}
        </PrimaryButtonLarge>
      </SubmitButtonContainer>
    </Form>
  );
};
